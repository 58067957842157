import { ServerList } from "../serverdatagrid";
import { Button, TextField } from "@mui/material";
import {Dialog,DialogActions,DialogTitle,DialogContent,DialogContentText} from "@mui/material";
import React, { useEffect } from "react";
import { sendServerRequest } from "../../utils/sendservr";
import { updatedServerList } from "../serverdatagrid";
import { updateSnackStatus } from "../snack";
import { LoadingButton } from "@mui/lab";
var globalOpenEdit=null;
const listHeader=[
    {id:'bm_name',label:"Nome"},
    {id:'updatedAt',label:"Ultima Atualização"},
    {id:'edit',label:'Editar',disableSearch:true,disableSort:true,showFunc:(item,head,i)=>{return <Button variant="contained" onClick={()=>globalOpenEdit(item)}>Editar</Button>}}
];

export function FacebookBM(){
    const [open,setOpen]=React.useState(false);
    const [isEdit,setIsEdit]=React.useState(false);
    const [bm,setBm]=React.useState(null);
    useEffect(()=>{
        globalOpenEdit=openEdit;
    })
    return (
        <div>
            <div>
                <h4>Gerenciador de negocios do Facebook</h4>
            </div>
            <div>
                <Button onClick={()=>{setIsEdit(false);setOpen(true)}} variant="contained">Adicionar BM</Button>
            </div>
            <div style={{marginTop:"15px"}}>
            <ServerList url={'/server/bm/list'} processData={processData} header={listHeader}/>
            </div>
            <AddOrEditBM isEdit={isEdit} bm={bm} open={open} closeDialog={()=>setOpen(false)}/>
        </div>
    )
    function processData(data){
        console.log(data);
        return {
            is_ok:true,
            data:data.data.list,
        }
    }
    function openEdit(bm){
        setOpen(true);
        setIsEdit(true);
        setBm(bm);
    }

}

function AddOrEditBM(props){
    const {open,isEdit,bm}=props;
    const [loading,setLoading]=React.useState(false);
    return (
        <Dialog
               open={open}
               onClose={()=>props.closeDialog()}
            >
            <DialogTitle>{isEdit?"Editar BM":"Adicionar BM"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {"Você deve colocar um token valido com todas as permissões necessárias."}
                </DialogContentText>    
            <DialogContent>    
                 <form>
                 <div style={{textAlign:"center"}}> 
                    <TextField  fullWidth name='bm_name' label="Nome da BM" defaultValue={isEdit?bm.bm_name:null} InputProps={{
                                                                                                                        readOnly: isEdit,
                                                                                                                    }}
                    />
                    <TextField fullWidth name='token' label="Token"/> 
                    <LoadingButton loading={loading} fullWidth variant="contained" onClick={send}>{isEdit?"Editar":"Adicionar"}</LoadingButton>
                 </div>   
                 </form>
            </DialogContent>
            </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{props.closeDialog()}}>Sair</Button>
                </DialogActions>
            </Dialog>
    )
    function send(btn){
        setLoading(true);
        const form=new FormData(btn.target.form);
        let sendData=JSON.stringify({
            token:form.get("token"),
            bm_name:form.get('bm_name'),
        });
        if(!isEdit){
            sendServerRequest('/server/bm/add',sendData).then((result)=>{
                    setLoading(false);
                    props.closeDialog();
                    updatedServerList();
                    updateSnackStatus({
                    open:true,
                    severity:'success',
                    message:'BM Adicionada com sucesso!',
                    });

            }).catch(e=>{
                const {status}=e.target;
                let message="Nome ja existe";
                if(status==500){
                    message='Token Invalido';
                }
                updateSnackStatus({
                    open:true,
                    severity:'error',
                    message:message,    
                })
                setLoading(false);
            });
        }else{
            sendServerRequest('/server/bm/edit',sendData).then((result)=>{
                setLoading(false);
                props.closeDialog();
                updatedServerList();
                updateSnackStatus({
                open:true,
                severity:'success',
                message:'BM Editada com sucesso!',
                });
            }).catch(e=>{
                setLoading(false);
                updateSnackStatus({
                    open:true,
                    severity:'error',
                    message:'Token Invalido!',    
                })
            });
        }
        
    }
}