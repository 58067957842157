import { ResponsivelLayout } from "./layout";
import { Instagram as InstagramIcon } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { getUserData } from "../global/user";
import { redirectToLoginPage } from "../utils/redirects";
import { CircularProgress } from "@mui/material";
import { Logout } from "./logout";
import { CalendarMonth } from "@mui/icons-material";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { Business } from "@mui/icons-material";
import { FacebookBM } from "./bm/bm";
import { FacebookInstagram } from "./instagram/instagram";
import { ModelCreator } from "./post/models";
import { PostShedule } from "./post/shedule";
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import Models from "./post/models";
import { PreparedPosts } from "./post/prepared";
const listItems=[
    {
        type:'accordion',
        summary:'Busisness Facebook',
        items:[
            {type:'item',icon:<Business/>,primary:'Negocios',href:'/app/dashboard/business/settings'},
        ]
    }, 
    {
        type:'accordion',
        summary:'Instagram',
        items:[
            {type:'item',icon:<InstagramIcon/>,primary:'Contas',href:'/app/dashboard/instagram/accounts'},
        ]
    },  
    {
        type:'accordion',
        summary:'Postagens',
        items:[
            {type:'item',icon:<ArtTrackIcon/>,primary:'Modelos',href:'/app/dashboard/posts/models'},
            {type:'item',icon:<CalendarMonth/>,primary:'Agendamentos',href:'/app/dashboard/posts/calendar'},
            {type:'item',icon:<CalendarMonth/>,primary:'Prontos',href:'/app/dashboard/posts/prepared'},
        ]
    }, 
    {type:'item',icon:<LogoutIcon/>,primary:'Deslogar',href:'/app/dashboard/logout'},
]  

function Content(props){ 
    const [location,setLocation]=useState(window.location.pathname.toString());
    const onUrlUpdate=()=>{
        setLocation(window.location.pathname.toString());
     }
    useEffect(()=>{
        window.addEventListener('onurlupdate',onUrlUpdate);
        return ()=>{
            window.removeEventListener('onurlupdate',onUrlUpdate)
        }
    });
    return (
       <div>
          <GetContent pathname={location}/>
       </div>
    )
    function GetContent(props){
        const {pathname}=props;
        switch(pathname){
            case '/app/dashboard/business/settings':
                return <FacebookBM/>
            case '/app/dashboard/logout':
                return <Logout/>;
            case '/app/dashboard/instagram/accounts':
                return <FacebookInstagram/>    
            case '/app/dashboard/posts/models':
                return <Models/>
            case '/app/dashboard/posts/calendar':
                return <PostShedule/>    
            case '/app/dashboard/posts/prepared':
                return <PreparedPosts/>
            default:  
                return (<div>
                    <p>Conteúdo não encontrado</p>
                </div>)  
        }
    }
}
 
export function Dashboard(){
    const [user,setUser]=React.useState(false);
    useEffect(()=>{
        if(user)
          return;
        getUserData().then((r)=>{
            if(!r.data.hasError){
                setUser(r.data.data);
                return;
            }
            redirectToLoginPage();
        }).catch(e=>{
            redirectToLoginPage();
        })
    })
    return (
       <>
          {user?<ResponsivelLayout logo={<p>{user.email}</p>} listItems={listItems} content={<Content/>}/>:
             <div style={{textAlign:'center',marginTop:"50px"}}>
                 <p>Carregando...</p>
                 <CircularProgress/>
             </div>
          }
       </> 
       
    )
}