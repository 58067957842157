import React from "react";
import { MyAppBar } from "./appbar";
import { MyMenu } from "./menu";
import { Drawer } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
export var globalSetForceLow=null;
var forceLowWidthMode=false;
function OpenMenuButton(props){
    return (
       <IconButton onClick={props.onClick}><MenuIcon fontSize="large" sx={{color:"#fff"}}/></IconButton>
    )
}

export class ResponsivelLayout extends React.Component{
    constructor(props){
        super(props);
        this.state={widthSize:'large',forceLowMenu:false};
        this.drawerWidth=this.props.drawerWidth?this.props.drawerWidth:300;
    }
    async componentDidMount(){
        this.resizeEvent=async ()=>{
           const lowWidthBreakPoint=this.props.lowWidthBreakPoint?this.props.lowWidthBreakPoint:830;
           if(window.innerWidth>lowWidthBreakPoint && !forceLowWidthMode){
               if(this.state.widthSize!=='large'){
                  await this.setState({widthSize:'large'});  
                  return;
               }  
               
           }else{
               if(this.state.widthSize!=='low'){
                  await this.setState({widthSize:'low'}); 
                  return;
               }  
           }
           
        };
        window.addEventListener('resize',this.resizeEvent);
        await this.resizeEvent();
        this.updateContentWidth();
    }
    resize(){
        this.resizeEvent();
    }
    componentDidUpdate(){
        this.updateContentWidth();
    }
    updateContentWidth(forceLow=false){
        const isLowWidth=(this.state.widthSize=="large"?false:true)||forceLowWidthMode;
        const marginLeft=isLowWidth?"0px":`${this.drawerWidth}px`;
        const width=isLowWidth?"100%":`calc(100% - ${this.drawerWidth}px)`;
        let elm=document.getElementById("app_content");
        if(!elm)
           return;
        elm.style.marginLeft=marginLeft;
        elm.style.width=width;
    }
    Content(props){
        return (
            <div
            id="app_content"
            >
                {props.content}
            </div>
        )
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.resizeEvent);
    }

    render(){
        const Content=this.Content.bind(this);
        return(
        <React.Fragment>
            <CssBaseline/>
           <Menu logo={this.props.logo} forceLow={this.updateContentWidth.bind(this)} drawerWidth={this.drawerWidth}  widthSize={this.state.widthSize} listItems={this.props.listItems} selectedIndex={this.props.selectedIndex}/>
           <Content content={this.props.content}/>

        </React.Fragment>)
    }
}



class Menu extends React.Component{
    constructor(props){
        super(props);
        this.state={open:false,forceLow:false};
    }
    setOpen(value){
       this.setState({open:value});
    }
    componentDidMount(){
        globalSetForceLow=(v)=>{forceLowWidthMode=v;this.setState({forceLow:v})};
    }
    componentDidUpdate(){
        this.props.forceLow();
    }
    render(){
        const drawerWidth=this.props.drawerWidth?this.props.drawerWidth:300;
        const isLarge=(this.props.widthSize=="large"&&!this.state.forceLow);
        return (
            <React.Fragment>
            <MyAppBar left={isLarge=="large"?null:<OpenMenuButton
                                                                   onClick={()=>this.setOpen(true)}
                                                                   />}/>
                    <Drawer
                        anchor='left'
                        variant={isLarge?"permanent":"temporary"}
                        open={this.state.open}
                        onClose={()=>this.setOpen(false)}
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                              width: drawerWidth,
                              boxSizing: 'border-box',
                            },
                          }}
                    >
                            <MyAppBar left={this.props.logo}/>
                            <MyMenu  selectedIndex={this.props.selectedIndex} items={this.props.listItems}/>
    
                    </Drawer>
            </React.Fragment>        
        )
    }
}
