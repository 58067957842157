import React from "react";
import { sendServerRequest } from "../../utils/sendservr";
import { CircularProgress, IconButton } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { createCanvas, loadImage } from "canvas";
import { updateSnackStatus } from "../snack";
import { LoadingButton } from "@mui/lab";
import './../../css/imgthum.css';
import {Paper} from "@mui/material";
import { sendForm } from "../../utils/form";
import {Backdrop} from "@mui/material";
import { useRef } from "react";
import {Button} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ImageResult } from "../post/cors";
function ImageField(props){
    return (
               <label htmlFor="icon-button-file">
                    <input style={{display:"none"}} name="image" onChange={(e)=>props.onChange(e)} required accept="image/*" id="icon-button-file" type="file" />
                    <LoadingButton loading={props.uploading} variant="contained" component="span">Carregar Imagem</LoadingButton>
                </label>
    )
}
export function ImageGalery({onSelectedImage}){
    const [images,setImages]=useState(null);
    const [uploading,setUploading]=useState(false);
    const cols=window.innerWidth>950?5:window.innerWidth>600?3:2;
    useEffect(()=>{
        if(!images){
            updateGalery();   
        }
    });
    return (
        <div style={{marginTop:"25px"}}>
              <form encType="multipart/form-data">
              <ImageField uploading={uploading} onChange={uploadImage}/>
              </form>
              <div style={{marginTop:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                 <Paper elevation={6}>
                  {images?<Galery imageSelected={imageSelected} cols={cols} width={cols*110} images={images}/>:<CircularProgress/>}
                  </Paper>
              </div>
             
        </div>
    )
    function uploadImage(e){
         const {value}=e.target;
         if(!value)
            return;
         const {form}=e.target;
         setUploading(true);
         const orsc=(e)=>{
             const {target}=e;
             const {readyState}=target;
             if(readyState==XMLHttpRequest.DONE){
                 let severity='success';
                 let message='Imagem adicionada com sucesso!';
                 if(target.status!==200){
                     severity='error';
                     message=`Cheque se é um arquivo no formado 'png' ou 'jpeg' e sua dimensão é 1080x1080`;
                 }else{
                    updateGalery();
                 }
                 updateSnackStatus({
                    open:true,
                    severity:severity,
                    message:message,
                 });
                 setUploading(false);
             }

         }
         sendForm('/server/img/upload',form,orsc);

     }
     function imageSelected(img){
           onSelectedImage(img);
     }
    
    
    function updateGalery(){
        sendServerRequest('/server/img/galery').then(r=>{
            const {images}=r.data.data;
            setImages(images);
         }).catch(e=>{
             updateSnackStatus({
                 open:true,
                 message:'Erro ao carregar galeria, atualize a página.',
                 severity:'error',
             });
         })
    }
}

export function Galery(props){
    const {images}=props;
    const {height}=props;
    const {width}=props;
    const {configs}=props;
    const {refs}=props;
    const cols=props.cols||5;   
    const rows=((images.length-1)/cols)+1;
    const [index,setIndex]=useState(-1);
    const [openBig,setOpenBig]=useState(false);
    const [data,setData]=useState(false);
    const [lastData,setLastData]=useState(false);
    useEffect(()=>{
         updateData();
         return ()=>{}
    },[]);
    useEffect(()=>{
        if(images!==lastData){
            updateData();
        }
    });
    return (
    <div className='galery' style={{height:`${height||400}px`,width:`${width}px`,margin:'auto',overflowY:'scroll'}}>
          <div>
          {
            data?data.map(e=>e):null
          }
          </div>
          <div>
            {openBig?
               <BigImageGalery configs={configs} imageSelected={imageSelected} changeIndex={setIndex} out={()=>setOpenBig(false)} width={width} open={openBig} index={index} images={images}/>
               :null}
            </div>
    </div>)
    function imageSelected(){
        if(openBig)
          props.imageSelected(images[index],refs?refs[index]:null);
    }
    function updateData(){
        setLastData(images);
        let ret=[]; 
        for(let row=0;row<rows;row++){
            let insert=[];
            for(let inn=row*cols;inn<row*cols+cols;inn++){
                if(inn>=images.length)
                    break;
                let config=null;
                let iref=null;
                if(configs)
                    config=configs[inn];  
                insert.push(<ImageThumb configs={config} onClick={(i)=>{setIndex(i);setOpenBig(true)}} id={inn} url={`/server/img/userimage/${images[inn].id}?isMini=true`}/>);
            }
            ret.push(<div style={{display:'flex'}}>
                {insert.map(e=>e)}
            </div>)
        }
        setData(ret);
    }
}

function ImageThumb(props){
    const {url,id,configs}=props;
    const width=props.width||100;
    const imgWidth=props.imgWidth||100;
    const imgHeight=props.imgHeight||100;
    const height=props.height||100;
    const [uri,setUri]=useState(null);
    return (
        <div style={{cursor:uri?props.blockClick?'inherit':'pointer':'progress',width:`${width+10}px`,height:`${height+10}px`,display:'flex',justifyContent:'center',alignItems:'center'}} className="image_thumb" onClick={()=>{if(uri&&!props.blockClick){props.onClick(id)}}} >
             <ImageResult configs={configs} onLoaded={setUri} imgWidth={1080} imgHeight={1080} showWidth={width}  url={url}/>
        </div>
    )
}


export default function BigImageGalery(props) {
    const {images,index,width,changeIndex,configs}=props;
    const ref=useRef();
    useEffect(()=>{
          document.addEventListener('keydown',keyDown);
          return ()=>{
           document.removeEventListener('keydown',keyDown);
          }
    },[]);
    useEffect(()=>{
         ref.current=props;
    })
    let disabledLeft=(index==0);
    let disabledRight=(index==images.length-1);
    if(index<0)
      return null;
    return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props.open}
        >
           <div style={{display:"block"}}>
            <div>
                <Button variant="contained" onClick={props.imageSelected}>Selecionar Imagem.</Button>
                <Button onClick={props.out} sx={{marginLeft:"5px"}} variant="contained">Fechar</Button>
            </div>
            <div style={{display:'flex',alignContent:"center"}}>
            <IconButton disabled={disabledLeft} onClick={()=>changeIndex(index-1)}><ArrowBackIosNewIcon sx={{color:`${disabledLeft?'rgb(127,127,127)':'#fff'}`}}/></IconButton>
            <ImageThumb configs={configs?configs[index]:null} blockClick width={width} height={width} url={`/server/img/userimage/${images[index].id}?isMini=false`}/>
            <IconButton disabled={disabledRight} onClick={()=>changeIndex(index+1)}><ArrowBackIosNewIcon sx={{transform:'rotate(180deg)',color:`${disabledRight?'rgb(127,127,127)':'#fff'}`}}/></IconButton>
            </div>
           </div>
        </Backdrop>
    );
    function keyDown(k){
        const {index}=ref.current;
        const {key}=k;
        if(key=='ArrowRight'){
            if(index<images.length-1)
                changeIndex(index+1);
        }
        if(key=='ArrowLeft'){
            if(index>0)
                changeIndex(index-1);
       }
    } 


  }