export function setAnyFirstLetterToUpper(value){
    let names=value.split(' ');
    let result='';
    names.map((v)=>{
      result+=(v.slice(0,1).toUpperCase()+v.slice(1)+" ");
    });
    result=result.slice(0,result.length-1);
    return result;
}
export function getJustChars(string,allowSpace=true){
 let ret='';
 for(let i=0;i<string.length;i++){
     let char=string[i].charCodeAt();
     if((char>64 && char<91) || (char>96 && char<123) || (allowSpace && char==32)){
        ret+=string[i];
     }
 }
 return ret;
}

export function formatDateString(date,hour=false){
  const day=setZeroTenLower(date.getDate());
  const month=setZeroTenLower(1+date.getMonth());
  const year=1900+date.getYear();
  const fHour=hour?` ${setZeroTenLower(date.getHours())}:${setZeroTenLower(date.getMinutes())}`:"";
  return `${day}/${month}/${year}${fHour}`;
  function setZeroTenLower(number){
      return number>9?number:`0${number}`;
  }
}