import React, { useEffect } from "react";
import { ServerList, updatedServerList } from "../serverdatagrid";
import { FormControl } from "@mui/material";
import {InputLabel} from "@mui/material";
import {Select} from "@mui/material";
import { MenuItem} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { sendServerRequest } from "../../utils/sendservr";
import { updateSnackStatus } from "../snack";
import { restartInstagramList } from "../../global/instagram";
const headers=[
    {id:'user_name',label:'Usuário @'},
    {id:'bm_name',label:'BM Proprietária'},
    {id:'bio',label:'Bio'},
]
export function FacebookInstagram(){
    return (
        <div>
            <div>
                <h4>Contas Do Instagram</h4>
            </div>
            <div>
                <UpdateInstagramAccounts/>
            </div>
            <div style={{marginTop:'15px'}}>
            <ServerList processData={processData} header={headers} url={"/server/instagram/list"}/>
            </div>
        </div>
    )
    function processData(data){
        return {
            is_ok:true,
            data:data.data.accounts,
        }
    }
}

function UpdateInstagramAccounts(){
    const [loading,setLoading]=React.useState(true);
    const [item,setItem]=React.useState(null);
    const [items,setItems]=React.useState(null);
    useEffect(()=>{
        if(!items)
          getBms();
    })
    return (
        <div style={{display:"flex",margin:"auto",justifyContent:"center"}}>
             <FormControl sx={{minWidth:"150px"}}>
                <InputLabel id="demo-simple-select-label">Nome Da BM</InputLabel>
                   <BMItems setItem={setItem} items={items} item={item}/>
            </FormControl>
            <LoadingButton onClick={updateInsta} loading={loading} variant="contained">Atualizar Contas</LoadingButton>
        </div>
    )
    function BMItems(props){
       const {items,item,setItem}=props;
       if(!items)
         return null;
       return (
            <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={item}
                    disabled={loading}
                    label="Age"
                    onChange={(i)=>setItem(i.target.value)}
                >
            {items&&items.map((t)=>{
                        return <MenuItem value={t.bm_name}>{t.bm_name}</MenuItem>
                    })}
            </Select>        
        
       )  
    }
    function updateInsta(){
        if(!item){
          updateSnackStatus({
            open:true,
            severity:"info",
            message:"Selecione Uma BM",
          });
          return;
        }
        setLoading(true);
        sendServerRequest('/server/instagram/update',JSON.stringify({bm_name:item})).then(r=>{
            updateSnackStatus({
                open:true,
                severity:"success",
                message:"Contas atualizadas com sucesso!",
            });
            setLoading(false);
            restartInstagramList();
            updatedServerList();
        }).catch(e=>{
            updateSnackStatus({
                open:true,
                severity:"error",
                message:"Error ao atualizar contas do instagram",
            });
            setLoading(false);
        })
    }
    function getBms(){
        sendServerRequest('/server/bm/list').then(r=>{
            setItems(r.data.data.list);
            setLoading(false);
        }).catch(e=>{
           updateSnackStatus({
              open:true,
              severity:"error",
              message:"Erro ao carregar lista de BM's. Atualize a pagina."
           })
        });
    }
}