import React, { useEffect, useState } from "react";
import {IconButton} from "@mui/material";
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import { Script } from "imagetextinsert";
import { updateSnackStatus } from "../snack";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import {  getFontSelectItems } from "../../global/fonts";

import {   TextareaAutosize } from "@mui/material";
import { BasicSelect } from "../inputs";
import {Button} from "@mui/material";
import '../../css/coord.css';
import '../../css/controls.css';

export function ImageResult({url,configs,showWidth,imgWidth,imgHeight,onLoaded}){
    const factor=showWidth/imgWidth;
    const [img,setImage]=useState(false);
    const [uri,setUri]=useState(false);
    const showHeight=imgHeight*factor;
    const [lastUrl,setLastUrl]=useState();
    const [lastConfs,setLastConfifs]=useState();
    useEffect(()=>{
        if(!img||lastUrl!==url||configs!==lastConfs){
            let nImg=new Image();
            nImg.src=url;
            nImg.onload=()=>{
                setImage(nImg);
                setLastUrl(url);
                setLastConfifs(configs);
            }
        }
        return ()=>{}
    });
    useEffect(()=>{
        if(img){
            insertTexts();
        }
        async function insertTexts(){
            let uImg=img;
            try{
                let obj;

                if(configs)
                for(let config of configs){
                    if(!config.config||typeof(config.config)=='boolean')
                       continue;
                    obj=await Script.insertTextOverImage(uImg,imgWidth*factor,imgHeight*factor);
                    let uConfig={};
                    Object.assign(uConfig,config.config);
                    if(config.text)
                    uConfig.text=config.text;
                    uConfig.xs=normalize(uConfig.xs);
                    uConfig.ys=normalize(uConfig.ys);
                    uConfig.maxWidth=normalize(uConfig.maxWidth);
                    uConfig.maxHeight=normalize(uConfig.maxHeight); 
                    await obj.insert(uConfig);
                    let nimg=new Image();
                    nimg.src=obj.canvas._canvas.toDataURL();
                    uImg=nimg;
                }
                setUri(uImg.src);    
           }catch(e){
            updateSnackStatus({
                open:true,
                message:'Erro ao carregar imagem, recarregue a pagina.',
                severity:'error',
            });
           }   
        }
        function normalize(v){
            return Math.floor(v*factor);
        }
    },[img]);
    useEffect(()=>{
       if(onLoaded)
         onLoaded(uri);
    },[uri])
    return (
        <div style={{width:`${showWidth}px`,height:`${showHeight}px`}}>
             {uri?<img width={`${showWidth}px`} src={uri}/>:null}
        </div>
    )
}

export function GetImageCoods(props){
    const {imgSrc}=props;
    const fonts=getFontSelectItems();
    const factor=props.showWidth?props.showWidth/props.imgWidth:1;
    const imgWidth=props.imgWidth*factor;
    const imgHeight=props.imgHeight*factor;
    const defaultColor=getDefaultColor(props.defaultConfig||{});
    const [color,setColor]=useState(defaultColor);
    const [image,setImage]=React.useState(null);
    const [obj,setObj]=React.useState(null);
    const defaultText=props.defaultConfig&&props.defaultConfig.text?props.defaultConfig.text:'Texto de exemplo';
    const [text,setText]=React.useState(defaultText);
    const defaultAlign=props.defaultConfig&&props.defaultConfig.alignMode?props.defaultConfig.alignMode:'center';
    const [align,setAlign]=React.useState(defaultAlign);
    const [centerHeight,setCenterHeight]=React.useState(true);
    const defaultFont=getDefaultFont(props.defaultConfig||{});
    const defaultSelectedFont=(()=>{
        for(let f of fonts){
            if(f.fontFamily==defaultFont)
               return f;
        }
    })()
    const [font,setFont]=React.useState(defaultFont);
    const defultCoords=getDefaultCoords(props.defaultConfig||{});
    const [cords,setCords]=React.useState(defultCoords);
    function getDefaultCoords({xs,ys,maxWidth,maxHeight}){
       return {
          xs:xs?normalizeToShow(xs):0,
          ys:ys?normalizeToShow(ys):0,
          maxHeight:maxHeight?normalizeToShow(maxHeight):100,
          maxWidth:maxWidth?normalizeToShow(maxWidth):200,
       }  
    }
    function getDefaultFont({font}){
        if(!font||!font.family)
            return fonts[0].fontFamily;
        return font.family;    
    }
    function getDefaultColor({font}){
        if(!font||!font.style)
            return '#000';
        return font.style;    
    }
    function normalizeToShow(v){
        return Math.floor(v*factor);
    }
    useEffect(()=>{
       if(!image){
          loadCanvasImage(imgSrc).then(async (i)=>{
               let uImage=i;
               if(props.preTexts){
                   for(let preText of props.preTexts){
                     let imgobj=await Script.insertTextOverImage(uImage,imgWidth,imgHeight);
                     const tConfig={};
                     Object.assign(tConfig,preText.config);
                     if(preText.text)
                       tConfig.text=preText.text;
                     tConfig.xs=normalizeToShow(tConfig.xs);  
                     tConfig.ys=normalizeToShow(tConfig.ys);
                     tConfig.maxWidth=normalizeToShow(tConfig.maxWidth);
                     tConfig.maxHeight=normalizeToShow(tConfig.maxHeight);
                     await imgobj.insert(tConfig);
                     let nImage=new Image();
                     nImage.src=imgobj.canvas._canvas.toDataURL();
                     uImage=nImage;
                   }
               }
               setImage(uImage);
               drawImage(uImage);
           }).catch(e=>{
               updateSnackStatus({
                  open:true,
                  message:'Erro ao carregar a imagem, atualize a página.',
                  severity:'error',
               });
           });
           
       }
    });
    useEffect(()=>{
        if(image)
         drawImage(image);
    },[cords,text,align,centerHeight,color,font])
    return (
        <div style={{marginTop:'10px'}}>
            <div>
             <Button variant="contained" onClick={onCoordsSelected}>Escolher cordenadas</Button>
            </div>
            <div style={{maxWidth:"500px",minWidth:"250px",margin:'auto'}}>
               <TextareaAutosize lines={2} style={{width:"100%"}} onChange={(e)=>{setText(e.target.value)}} defaultValue={defaultText}/>
               <div className="controls">
               <div className="group_controls">
                  <IconButton color={align==='left'?'success':'default'} onClick={()=>setAlign('left')}><FormatAlignLeftIcon/></IconButton>
                  <IconButton color={align==='center'?'success':'default'} onClick={()=>setAlign('center')}><FormatAlignCenterIcon/></IconButton>
              </div>
              <div className="group_controls">
                 <IconButton color={centerHeight?'success':'default'}><AlignVerticalCenterIcon onClick={()=>{setCenterHeight(!centerHeight)}}/></IconButton>
              </div>
              <div style={{display:'flex',alignItems:'center',borderWidth:'0px'}} className="group_controls">
                  <input defaultValue={defaultColor} onChange={(e)=>{setColor(e.target.value)}} type="color"/>
              </div>
              <div className="group_controls" style={{minWidth:'200px'}}>
                 <BasicSelect label={'Fontes'} items={fonts} onItemChange={(i)=>{setFont(i.fontFamily)}} defaultItem={defaultSelectedFont}/>
              </div>
              </div>
            </div>
            <div style={{marginTop:"20px"}}>
               <ImageE defaultCoords={defultCoords} onUpdateCoods={onUpdateCoods} imgWidth={imgWidth} imgHeight={imgHeight} c={obj?obj.canvas._canvas:null}/>
            </div>
            
        </div>
    )
    function onUpdateCoods(xs,ys,x,y){
        let nobj={};
        nobj.maxWidth=x;
        nobj.maxHeight=y;
        nobj.xs=xs;
        nobj.ys=ys;
        setCords(nobj);
    }
    function getCoordObj(){
     let tConfig={};
     tConfig.xs=cords.xs;
     tConfig.ys=cords.ys;
     tConfig.maxWidth=cords.maxWidth;
     tConfig.maxHeight=cords.maxHeight;
     tConfig.text=text;
     tConfig.alignMode=align;
     tConfig.centerHeight=centerHeight;
     tConfig.font={};
     tConfig.font.style=color;
     tConfig.font.family=font;
     tConfig.font.adjust_size=true;
     return tConfig;
    }
    async function drawImage(i){
       let imgobj=await Script.insertTextOverImage(i,imgWidth,imgHeight);
       imgobj.canvas._image=i;
       const config=getCoordObj();
       await imgobj.insert(config);
       setObj(imgobj);
    }
    
    function loadCanvasImage(src){
         return new Promise((resolve)=>{
             let img = new Image();
             if(typeof(src)==='string'){
                 img.src = src;
             }
             img.onload = function() {
                 resolve(img);
             };
         })    
    }
    function onCoordsSelected(){
        const tObj=getCoordObj();
        tObj.xs=normalize(tObj.xs);
        tObj.ys=normalize(tObj.ys);
        tObj.maxWidth=normalize(tObj.maxWidth);
        tObj.maxHeight=normalize(tObj.maxHeight);
        props.onCoordsSelected(tObj);
        function normalize(v){
            let ret=v/factor;
            return Math.floor(ret);
        }
    }
 }
 
 function CoordRectangle(props){
         const {config}=props;
         const [cords,setCords]=useState({x:config?.maxWidth||200,y:config?.maxHeight||100,xs:config?.xs||0,ys:config?.ys||0});
         const ref={current:cords}
         let lastClientX=0;
         let lastClientY=0;
         const {maxWidth,maxHeight}=props;
         useEffect(()=>{
             updateCords(cords.x,cords.y,cords.xs,cords.ys);
         },[cords]);
         return(   
             <div>     
             <div id="coord_center_div" style={{position:"absolute"}}>
              <div draggable="true" onDragStart={(e)=>{lastClientX=e.clientX;lastClientY=e.clientY;}} onDrag={onDragBase} onDragEnd={onDragEnd} className="coord"></div>
              <div draggable="true" onDragStart={(e)=>{lastClientX=e.clientX;lastClientY=e.clientY;}} onDrag={onDragPointerRightBottom} onDragEnd={onDragEnd} className="coord_pointer nwse" ></div>
              </div> 
              <div style={{top:`${maxWidth}px`,position:'absolute',width:"100%"}}>
                 <div style={{textAlign:"center"}}>
                 <IconButton onClick={horizontalAlign}><VerticalAlignCenterIcon sx={{transform:'rotate(90deg)'}}/></IconButton>
                 <IconButton onClick={verticalAlign}><VerticalAlignCenterIcon/></IconButton>
                 </div>
              </div> 
             </div>
                 
         )
         function onDragEnd(){
             if(props.onDragFinish){
                 const {x,y,xs,ys}=ref.current; 
                 setCords({x,y,xs,ys});
                 props.onDragFinish(xs,ys,x,y);
             }
         }
         function horizontalAlign(){
              const {x,y,ys}=ref.current; 
              let newXs=(maxWidth-x)/2;
              updateCords(x,y,newXs,ys);
              onDragEnd();
         }
         function verticalAlign(){
             const {x,y,xs}=ref.current; 
             let newYs=(maxHeight-y)/2;
             updateCords(x,y,xs,newYs);
             onDragEnd();
         }
         function onDragPointerRightBottom(e){
             const vars=getVars(e);
             if(!vars)
               return;
             const {x,y,xs,ys}=ref.current;   
             const {varx,vary}=vars;  
             updateCords(x+varx,y+vary,xs,ys);
         }
         function onDragBase(e){
             if(e.clientX==0&&e.clientY==0&&e.pageX==0&&e.pageY==0)
                return;
             const {x,y,xs,ys}=ref.current;   
             let varx=e.clientX-lastClientX;
             let vary=e.clientY-lastClientY;
             lastClientX=e.clientX;
             lastClientY=e.clientY;
             updateCords(x,y,xs+varx,ys+vary);
             e.preventDefault();
         }
         function updateCords(x,y,xs,ys){
              if(x<0||y<0||xs<0||ys<0)
                  return;
              let ux=Math.min(Math.max(10,x),maxWidth-ref.current.xs);
              let uy=Math.min(Math.max(y,10),maxHeight-ref.current.ys);
              let uxs=Math.min(Math.max(xs,0),maxWidth-ref.current.x);
              let uys=Math.min(Math.max(ys,0),maxHeight-ref.current.y);
              const base=document.getElementById("coord_center_div");
              base.children[0].style.width=`${ux}px`;
              base.children[0].style.height=`${uy}px`;
              base.children[0].style.left=`${uxs}px`;
              base.children[0].style.top=`${uys}px`;
              base.children[1].style.left=`${uxs+ux}px`;
              base.children[1].style.top=`${uys+uy}px`;
              ref.current={
                x:ux,
                y:uy,
                xs:uxs,
                ys:uys,
              }
     }
 
     function getVars(e){
             if(e.clientX==0&&e.clientY==0&&e.pageX==0&&e.pageY==0)
                return;
             let varx=e.clientX-lastClientX;
             let vary=e.clientY-lastClientY;
             lastClientX=e.clientX;
             lastClientY=e.clientY;
             return {varx,vary};
     }
 }


 function ImageE(props){
    const {c,imgHeight,imgWidth}=props;
    if(!c)
      return null;  
    let data=c.toDataURL();

    return (
        <div style={{width:`${imgWidth}px`,height:`${imgHeight}px`,margin:'auto'}}>
           <div style={{width:`${imgWidth}px`,height:`${imgHeight}px`,position:"absolute"}}>
               <CoordRectangle config={props.defaultCoords} onDragFinish={(x,y,xs,ys)=>props.onUpdateCoods(x,y,xs,ys)} maxWidth={imgWidth} maxHeight={imgHeight}/>
           </div>
           <img src={data}/>
        </div>
    )
 }