import { sendServerRequest } from "../utils/sendservr";

export async function getInstagramList(){
    try{
        if(!window.userInsta){
            const result=await sendServerRequest('/server/instagram/list');
            const {accounts}=result.data.data;
            window.userInsta=accounts;
        }
        return window.userInsta;
    }catch(e){
        throw e;
    }
}
export async function restartInstagramList(){
    window.userInsta=null;
}