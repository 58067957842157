import { List } from "@mui/material";
import ListItemButton from '@mui/material/ListItemButton';
import { ListItemIcon } from "@mui/material";
import { ListItemText } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { changeLocationPathName } from "../utils/location";
import {IconButton} from "@mui/material";
import React from "react";
import {ListItem} from "@mui/material";


export function MyMenu(props){
    let accordionLastIndex=-1;
    let itemLastIndex=-1;
    let [selectedItemIndex,setSelectedItemIndex]=React.useState(-1);
    let [open,setOpen]=React.useState({});
    function GetList(e,accordionNivel){
        if(e.type=='accordion'){
            return GetAccordion(e,accordionNivel+1);
        }else{
            return GetItem(e);
        }
    }
    function GetAccordion(e,accordionNivel){
        accordionLastIndex++;
        let accordionIndex=accordionLastIndex.toString();
        if(open[accordionIndex]==undefined)
          open[accordionIndex]=Boolean(e.open);
        return (
            <ListItemButton disableGutters={true}  key={`accordion-${accordionIndex}`} sx={{paddingLeft:'0px'}}>
                <Accordion expanded={open[accordionIndex]} onChange={(event,r)=>onChange(event,e,accordionIndex,r)} sx={{width:'100%',backgroundColor:!(accordionNivel%2)?"#f8f8ff":"#ffffff"}}>
                    <AccordionSummary sx={{}} expandIcon={<ExpandMoreIcon/>}><div style={props.accordionSummaryStyle}>{e.summary}</div></AccordionSummary>
                    <AccordionDetails sx={{padding:0}}>
                    {e.items.map((e,i)=>{
                        return GetList(e,accordionNivel);
                    })}
                    </AccordionDetails>
                </Accordion>
            </ListItemButton>
        )
        function onChange(event,item,index,r){
            let newOpen={};
            Object.assign(newOpen,open);
            newOpen[index]=r;
            setOpen(newOpen);
            if(props.onAccordionChange){
                props.onAccordionChange(event,item,index,r);
            }   
        }
    }
    
    function onClickItem(item){
        if(props.onClick){
            props.onClick(item);
        }
        if(item.onClick){
            item.onClick();
        }else{
          changeLocationPathName(item.href);
        }  
    }
    function GetItem(e){
        itemLastIndex++;
        let itemIndex=itemLastIndex;
        return(
            <React.Fragment>
            {typeof(e.primary)=="string"?    
            <ListItem
                key={`item-${itemLastIndex}`}
                secondaryAction={
                    e.secondaryAction
                }
            >
            <ListItemButton disableGutters={true} 
                            //sx={{paddingLeft:'5px'}} 
                            onClick={()=>{setSelectedItemIndex(itemIndex);onClickItem(e)}} 
                            selected={selectedItemIndex===itemLastIndex} 
                            key={`item-buttom-${itemLastIndex}`}
                            
                            >
                {e.icon?<ListItemIcon>{e.icon}</ListItemIcon>:null}
                <ListItemText primary={e.primary} secondary={e.secondary}/>
                {e.iconButton?<IconButton onClick={e.iconButton.onClick}>{e.iconButton.icon}</IconButton>:null}
                {e.rightElm?e.rightElm:null}
            </ListItemButton></ListItem>:<ListItem key={itemLastIndex.toString()}>{e.primary}</ListItem>}
            </React.Fragment>
        ) 
    }
    return (
        <List>
            {props.items.map((e,i)=>{
               return GetList(e,0);   
            })}
        </List>
    )
}