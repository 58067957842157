
import React, {  useEffect, useState } from "react";
import {  CircularProgress, IconButton, Tabs, Typography } from "@mui/material";
import { BasicSelect, NumberField, UpperCaseTextField } from "../inputs";
import {Button} from "@mui/material";
import { ImageGalery } from "../images/galery";
import { GetImageCoods } from "./cors";
import {Paper} from "@mui/material";
import { ImageResult } from "./cors";
import { updateSnackStatus } from "../snack";
import { sendServerRequest } from "../../utils/sendservr";
import { useRef } from "react";
import { Galery } from "../images/galery";
import { getImageUrl } from "../../global/img";
import {Tab} from "@mui/material";
import {Box} from "@mui/material";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import {TabList} from "@mui/lab";
import {  getModelsImageConfigsByType } from "../../global/models";
import { resetUserModels } from "../../global/models";
import './../../css/post.css';
import './../../css/models.css';
import {InputAdornment} from "@mui/material";
import {TextField} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
const modelsTypes={
    thinks:{
        label:'Sobre',
        helper:'Postangem com uma unica imagem, um titulo sobre a imagem, e um texto explicando sobre o tema.',
        Component:AboutModelComponent,
        exemple:{
            descripton:'Na descrição, você encontrará o conteúdo gerado pela inteligência artificial.\n Aqui, apresentaremos um texto de tamanho médio, equivalente a cerca de dois minutos de leitura, abordando o tema escolhido.Nesta seção, mergulharemos no assunto selecionado, fornecendo informações relevantes e interessantes para o leitor. \nExploraremos os aspectos-chave e ofereceremos uma perspectiva aprofundada sobre o tema em questão.Com uma linguagem clara e concisa, nosso objetivo é transmitir o máximo de conhecimento possível, mantendo o texto envolvente e acessível a todos os leitores. Faremos uso de exemplos ilustrativos e dados pertinentes para enriquecer a compreensão e tornar a leitura ainda mais cativante.Ao longo desses dois minutos, você poderá expandir seu conhecimento sobre o tópico, adquirindo informações úteis que podem ser aplicadas em diferentes contextos. Esperamos proporcionar uma experiência enriquecedora e agradável durante a leitura deste texto.',
            hashtags:'#HashTag1 #HashTag2 #HashTag3',
        }
    },
    checklist:{
        label:'Checklist',
        helper:'Postagem com conteúdo na imagem, em formato de checklist e uma breve descrição.',
        Component:CheckListModelComponent,
        exemple:{
            hashtags:'#HashTag1 #HashTag2 #HashTag3',
            descripton:'Aqui será fornecida uma breve descrição abordando o tema apresentado na imagem.'
        }
    },
};

export default function Models() {
    const [tabIndex, setTabIndex] = React.useState('list');
  
  
    return (
      <Box sx={{ width: '100%' }}>
        <TabContext  value={tabIndex}>  
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList sx={{margin:'auto'}} centered onChange={(a,b)=>setTabIndex(b)}> 
            <Tab label="Lista" value='list'/>
            <Tab label="Criador"  value='creator'/>
            </TabList>
        </Box>
        <TabPanel label={'Lista'} value={'list'}>
           <ModelsServerGalery/>
        </TabPanel>
        <TabPanel label={'Criador'} value={'creator'}>
            <ModelCreator/>
        </TabPanel>
        </TabContext>
      </Box>
    );
  }

export function ModelCreator(props){
      const [model,setModel]=useState(props.model);
      const [data,setData]=useState(props.data);
      return (
          <div>
              <Typography sx={{marginBottom:"15px"}} variant="h6">Criador e editor de modelos.</Typography>
              {
                model?
                <div>
                <ModelComponent model={model}/>
                <Button sx={{marginTop:'50px',width:'200px'}}  variant='contained' onClick={()=>{setModel(false)}}>Sair</Button>
                </div>
                :
                <div>
                <Typography variant="p">Selecione o tipo de post.</Typography>    
                <SelectModel onItemChange={modelChange}/>
                </div>
              }
          </div>
      )
      function modelChange(m){
          setModel(m);
      }
      function ModelComponent(props){
        const {model}=props;
        if(!model)
          return null;
        const Component=modelsTypes[model].Component;
        return <Component {...props}/>
      }
}

function AboutModelComponent(props){
    const {data}=props;
    const [img,setImg]=useState(data?data.img:null);
    const [s1,setS1]=useState(data?data.s1:null);
    const [sAt,setSAt]=useState(data?data.sAt:null);
    const [saved,setSaved]=useState(false);
    const [name,setName]=useState("");
    const [defaultS1,setDefaultS1]=useState({text:'Título de exemplo.'});
    const [defaultsAt,setDefaultsAt]=useState({ys:0,maxHeight:60,maxWidth:250,xs:415,text:'@username'});
    useEffect(()=>{
      setS1(null);
    },[defaultS1]);
    useEffect(()=>{
       setSAt(null);
    },[defaultsAt]);
    return(
        <div style={{marginTop:'20px'}}>
            {!img?<div>
                <Typography variant="h6">Selecione a imagem principal do post.</Typography>
                <ImageGalery onSelectedImage={setImg}/>
                </div>:null}
            {img&&!s1?
            <div>
            <Typography variant="h6">Selecione a região do título.</Typography>    
            <Button fullWidth variant="contained" onClick={()=>setS1(true)} sx={{maxWidth:'500px'}}>Não Adicionar Titulo</Button>
            <GetImageCoods defaultConfig={defaultS1} onCoordsSelected={setS1} imgWidth={1080} imgHeight={1080} showWidth={500}  imgSrc={getImageUrl(img,false)}/>
            </div>
            :null}
            {img&&s1&&!sAt?<div>
              <Typography variant="h6">Selecione a região do @username.</Typography>    
              <GetImageCoods defaultConfig={defaultsAt} defaultText='@username'  preTexts={typeof(s1)=='boolean'?null:[{config:s1,text:'Título sobre o tema a se falar.'}]} onCoordsSelected={setSAt} imgWidth={1080} imgHeight={1080} showWidth={500}  imgSrc={`/server/img/userimage/${img.id}?isMini=false`}/>
            </div>:null}
            {img&&s1&&sAt?
                <div>
                <div style={{display:'flex',flexDirection:"column",justifyContent:"center",maxWidth:'350px',margin:'auto'}}>
                <Typography variant="h6" sx={{marginBottom:'8px'}}>Um exemplo de como ficará sua postagem com esse modelo.</Typography>
                {saved?
                 <div>
                     <Typography variant='subtitle1'>Esse modelo está salvo, não é possivel editar. Para isso é preciso criar um novo.</Typography>
                 </div>
                :<div>    
                   <UpperCaseTextField label='Nome Do Modelo' value={name} getonchange={(v)=>setName(v)}/>
                   <Button variant="contained" disabled={!name} onClick={saveModel} sx={{marginTop:'8px'}}>Salvar Modelo</Button>
                   <Button variant="contained" sx={{marginTop:'8px'}} onClick={updateTitle}>Editar Posição do Titulo</Button>
                   <Button variant="contained" sx={{marginTop:'8px'}} onClick={updateAt}>Editar posição do @username</Button>
                </div>} 
                </div>        
                <UniquePostExemple url={`/server/img/userimage/${img.id}?isMini=false`} configs={[{config:typeof(s1)=='boolean'?false:s1,text:'Título sobre o tema a se falar.'},{config:sAt,text:'@username'}]} descripton={modelsTypes.thinks.exemple.descripton} hashtags={modelsTypes.thinks.exemple.hashtags}/>
                
                </div>:null
            }
        </div>
    )
    function updateAt(){
        if(!sAt)
          return;
        let obj={};
        Object.assign(obj,sAt);
        obj.text='@username';
        setDefaultsAt(obj);  
    }
    function updateTitle(){
        if(!s1)
          return;
        let obj={};
        Object.assign(obj,typeof(s1)=='boolean'?defaultS1:s1);
        obj.text='Título de exemplo';
        setDefaultS1(obj);  
    }
    async function saveModel(){
         try{
              const data={
                  image_id:img.id,
                  name:name,
                  titleConfig:typeof(s1)=='boolean'?null:s1,
                  atConfig:sAt,
              }
              const result=await sendServerRequest('/server/models/create/thinks',JSON.stringify(data));
              updateSnackStatus({
                open:true,
                message:'Modelo Salvo com Sucesso.',
                severity:'success',
              });
              setSaved(true);
              resetUserModels();
         }catch(e){
              updateSnackStatus({
                open:true,
                message:'Erro ao salvar modelo, atualize a página.',
                severity:'error',
              })
         }
    }
}


export function UniquePostExemple({url,descripton,hashtags,configs,editable,post}){
    const [lastDescripton,setLastDescription]=useState(descripton||'');
    const [lastHashtags,setLastHashtags]=useState(hashtags||'');
    const [eDescription,setEDescription]=useState(descripton||'');
    const [eHashtags,setEHashtags]=useState(hashtags||'');
    const [editing,setEditing]=useState(false);
    const [pDescription,setPDescription]=useState(descripton);
    const [pHashtags,setPHashtags]=useState(hashtags);
    useEffect(()=>{
        if(pDescription!==descripton||hashtags!==pHashtags){
          reset();
        }
    })
    return (
        <div className="unique_post_exemple">
                    <Paper>
                        <ImageResult url={url} configs={configs} imgWidth={1080} imgHeight={1080} showWidth={500}/>
                        <Paper sx={{textAlign:'left',padding:'5px'}}>
                            <EditableTextField editable={editable} onEditEnd={setEDescription} value={eDescription||modelsTypes.thinks.exemple.descripton}/>
                            <EditableTextField editable={editable} onEditEnd={setEHashtags} style={{color:'rgb(192,207,219)'}} value={eHashtags||modelsTypes.thinks.exemple.hashtags}/>
                        </Paper>
                    </Paper>
                    {editable?
                        <div>
                           <LoadingButton loading={editing} fullWidth variant='contained' onClick={editPost} disabled={lastDescripton==eDescription&&lastHashtags==eHashtags}>Editar</LoadingButton>
                        </div>
                    :null}
                    
                </div>
    )
    function reset(){
      setLastDescription(descripton);
      setLastHashtags(hashtags);
      setEDescription(descripton);
      setEHashtags(hashtags);
      setPDescription(descripton);
      setPHashtags(hashtags);
    }
    async function editPost(){
        try{
           setEditing(true);
           let result=await sendServerRequest(`/server/posts/edit/${post.id}`,JSON.stringify({content:eDescription,hashtags:eHashtags}),'POST');
           setLastDescription(eDescription);
           setLastHashtags(eHashtags);
           setEditing(false);
           updateSnackStatus({
            severity:'success',
            message:'Post atualiado com sucesso!',
            open:true,
           });
        }catch(e){
            updateSnackStatus({
              severity:'error',
              message:'Erro ao editar postagem',
              open:true,
            });
        }
    }
    function EditableTextField({value,editable,style,onEditEnd}){
          const [editMode,setEditMode]=useState(false);
          const [useValue,setUseValue]=useState(value||'');
          return (
            <TextField multiline fullWidth style={style} onChange={changeValue} defaultValue={value} InputProps={{readOnly:!editable||!editMode,endAdornment:<>{editable?<InputAdornment position="end"><IconButton onClick={()=>{if(editMode)onEditEnd(useValue);setEditMode(!editMode);}}>{editMode?<CheckIcon/>:<EditIcon/>}</IconButton></InputAdornment>:null}</>}}/>
          )
          function changeValue(e){
               setUseValue(e.target.value);
          }
    }
}

function CheckListModelComponent(props){
    const [tempN,setTempN]=useState(3);
    const [n,setN]=useState(false);
    const [img,setImg]=useState(false);
    const [dTitleC,setDTitleC]=useState({text:'Título de exemplo.'});
    const [titleConfig,setTitleConfig]=useState(false);
    const [configs,setConfigs]=useState(false);
    const [dAt,setDAt]=useState({ys:0,maxWidth:300,maxHeight:60,xs:390,text:'@username'});
    const [atConfig,setAtConfig]=useState(false);
    const [saved,setSaved]=useState(false);
    const ref=useRef({name:''});
    useEffect(()=>{
      setTitleConfig(false);
    },[dTitleC])
    useEffect(()=>{
        setAtConfig(false);
      },[dAt])
    return (
        <div>
            {!n?<div className="check_list_form_div">
                <Typography variant="h6">Selecione o número de checks da sua imagem.</Typography>
                <NumberField onChange={setTempN} label='Numero de checks' defaultValue={3} min={1} max={10}/>
                <Button variant='contained' onClick={()=>setN(tempN)} fullWidth>Selecionar.</Button>
            </div>:null}
            {n&&!img?
            <div>
               <Typography variant="h6">Selecione uma imagem.</Typography> 
               <ImageGalery onSelectedImage={setImg}/>
            </div>:null}
            {n&&img&&!titleConfig?
            <div>
                 <Typography variant="h6">Selecione a posição do título.</Typography>
                 <GetImageCoods defaultConfig={dTitleC} onCoordsSelected={setTitleConfig} imgWidth={1080} imgHeight={1080} showWidth={500}  imgSrc={`/server/img/userimage/${img.id}?isMini=false`}/>
            </div>:null
            }
            {n&&img&&titleConfig&&(!configs||configs.length<n)?<div style={{textAlign:'center'}}>
                <Typography variant="h6">{`Selecione a posição do check ${configs?configs.length+1:1}`}</Typography>
                <Button disabled={!configs} onClick={back} variant="contained">Voltar Para o Check Anterior</Button>
                <ImageConfigs configs={configs} titlec={titleConfig} maxHeight={1080} updateConfigs={updateConfigs} />
            </div>:null}
            {n&&img&&titleConfig&&(configs&&configs.length==n)&&!atConfig?<div>
               <Typography variant="h6">Selecione a posição do @username.</Typography>
               <GetAtCoods def={dAt} onUp={setAtConfig} configs={configs} title={titleConfig}/>
            </div>:null}
            {n&&img&&titleConfig&&(configs&&configs.length==n)&&atConfig?<div>
               <Typography variant="h6" sx={{marginBottom:'10px'}}>Um exemplo de como ficará seu post.</Typography>
               {saved?<Typography>Esse modelo está salvo, não é possivel editar. Para isso é preciso criar um novo.</Typography>:
               <div className="check_list_form_div">
                <UpperCaseTextField label='Nome Do Modelo' getonchange={(v)=>ref.current.name=v}/>
                <Button variant="contained" onClick={saveModel} sx={{marginTop:'8px'}}>Salvar Modelo</Button>
                <Button onClick={editTitle} variant="contained">Editar Título</Button>
                <Button onClick={back} variant="contained">Editar Checks</Button>
                <Button onClick={editAt} variant="contained">Editar @username</Button>
                </div>}
               <ShowExemple title={titleConfig} configs={configs} at={atConfig}/>
            </div>:null}
        </div>
    )
    async function saveModel(){
         try{
           const {name}=ref.current;
           if(!name){
               updateSnackStatus({
                open:true,
                message:'Escolha um nome para o seu modelo',
                severity:'info',
               });
               return;
           }
           const data=JSON.stringify({
             name,
             atConfig:atConfig,
             configs:configs,
             titleConfig:titleConfig,
             numberOfChecks:n,
             image_id:img.id,
           });
           const result=await sendServerRequest('/server/models/create/checklist',data,'POST');
           updateSnackStatus({
             open:true,
             message:'Modelo salvo com sucesso',
             severity:'success',
           });
           resetUserModels();
           setSaved(true);
         }catch(e){
             updateSnackStatus({
                open:true,
                message:'Erro ao salvar modelo, atualize a pagina.',
                severity:'error',
             });
         }
    }
    function editTitle(){
        let cf={};
        Object.assign(cf,titleConfig);
        cf.text='Título de exemplo';
        setDTitleC(cf);
    }
    function editAt(){
        let cf={};
        Object.assign(cf,atConfig);
        cf.text='@username';
        setDAt(cf);
    }
    function back(){
        let newConfs=[];
        for(let i=0;i<configs.length-1;i++){
           newConfs.push(configs[i]);
        }
        if(newConfs.length==0)
          newConfs=false;
        setConfigs(newConfs);  
    }
    function updateConfigs(val){
        let newConfigs=[];
        if(configs){
            for(let conf of configs){
                newConfigs.push(conf);
            }
        }
        newConfigs.push(val);
        setConfigs(newConfigs);   
    }
    function ShowExemple({title,configs,at}){
       return (
        <UniquePostExemple configs={getPreTexts(title,configs,at)} url={`/server/img/userimage/${img.id}?isMini=false`} descripton={modelsTypes.checklist.exemple.descripton} hashtags={modelsTypes.checklist.exemple.hashtags}/>
       )
       
    }
    function ImageConfigs(props){
        const {configs,titlec,updateConfigs,maxHeight}=props;
        return (
            <GetImageCoods preTexts={getPreTexts(titlec,configs)} defaultConfig={getDefaultConfig()} onCoordsSelected={updateConfigs} imgWidth={1080} imgHeight={maxHeight} showWidth={500}  imgSrc={`/server/img/userimage/${img.id}?isMini=false`}/>   
        )
        function getDefaultConfig(){
              if(!configs)
                 return {
                    text:`Conteúdo do check número 1`,
                 }
              let cf={};
              Object.assign(cf,configs[configs.length-1]);
              cf.text=`Conteúdo do check número ${configs.length+1}`;
              if((cf.ys+cf.maxHeight+10)>maxHeight){
                 cf.ys=0;
              }else{
                cf.ys=cf.ys+cf.maxHeight+10;
              }
              return cf;
        }
    }
    function getPreTexts(tf,cf,at){
        let ret=[];
        ret.push({
            config:tf,
            text:'Título de exemplo'
        });
        for(let index in cf){
           let config=configs[index];
           ret.push({
             config:config,
             text:`Conteúdo do check número ${parseInt(index)+1}`,
           });
        }
        if(at){
            ret.push({
                config:at,
                text:'@username',
            })
        }
        return ret;
    }
    function GetAtCoods({title,configs,onUp,def}){
        return (
            <GetImageCoods preTexts={getPreTexts(title,configs)} defaultConfig={def} onCoordsSelected={onUp} imgWidth={1080} imgHeight={1080} showWidth={500}  imgSrc={`/server/img/userimage/${img.id}?isMini=false`}/>   
        )
    }
}

function SelectModel({onItemChange}){
    const [item,setItem]=useState(false);
    return(
          <div style={{maxWidth:"700px",minWidth:"200px",margin:"auto",marginTop:"50px"}}>
            <div style={{maxWidth:"400px",minWidth:"150px",margin:"auto"}}>
            <SelectWithTypeModels onItemChange={(i)=>setItem(i.value)} label={"Tipo de modelos"}/>
            <p>{!item?'':modelsTypes[item].helper}</p>
            <Button disabled={!item} fullWidth onClick={()=>onItemChange(item)} variant='contained'>Avançar</Button>
            </div>
          </div>
      )
      
      
}


export function SelectWithTypeModels({onItemChange,label,defaultValue,disabled}){
    return(
        <BasicSelect defaultValue={defaultValue} disabled={disabled} onItemChange={onItemChange} label={label||"Tipo de modelos"} items={getTypeModelItems()}/>
    )
    function getTypeModelItems(){
        let items=[];
        const models=Object.keys(modelsTypes);
        for(let key of models){
            items.push({
              label:modelsTypes[key].label,
              value:key,
            });
        }
        return items;
    }
}

export function ModelsServerGalery({onSetModel,defaultModel}){
    const [config,setConfig]=useState();
    const [postScript,setPostScript]=useState();
    const [model,setModel]=useState();
    const cols=window.innerWidth>950?5:window.innerWidth>600?3:3;
    useEffect(()=>{

    });
    useEffect(()=>{
       if(config){
         setConfig(false);
       }else{
         updateConfig();
       }
    },[postScript]);
    useEffect(()=>{
       if(!config)
         updateConfig();  
       if(defaultModel&&config&&!model){
          const {ids}=config;
          for(let m of ids){
             if(m.id==defaultModel.id){
                setModel(m);
                setPostScript(m.postScript);
             }   
          }
       }  
    },[config])
    return (
        <div className="models_list">
            <SelectWithTypeModels defaultValue={postScript} onItemChange={(e)=>setPostScript(e.value)}/>
            <div style={{marginTop:'15px'}}>
                {postScript&&!model?
                <div>
                {config?  
                  <ModelsGalery onModelSelected={(m)=>{onSetModel&&onSetModel(m);setModel(m)}} imgWidth={100} cols={cols} images={config.images} configs={config.configs} modelsid={config.ids} />
                :<CircularProgress/>}
                </div>:null
                }
                {model?
                        <div style={{width:'100%',marginTop:'20px',textAlign:'center'}}>
                          <Button  variant='contained' onClick={()=>setModel(false)}>Ver Outro Modelo</Button>  
                          <UniquePostExemple url={getImageUrl(model.images[0])} configs={model.configs[0]}/>
                </div>:null}
             </div>
        </div>
    )
    function updateConfig(){
        if(!postScript){
            if(defaultModel&&!config){
            }else{
                return;
            }
        }
        getModelsImageConfigsByType(postScript).then((mds)=>{
              const cf=[];
              const imgs=[];
              const ids=[];
              for(let m of mds){
                 cf.push(m.configs[0]);
                 imgs.push(m.images[0]);
                 ids.push(m);
              }   
              setConfig({
                configs:cf,
                images:imgs,
                ids:ids,
              });
        }).catch(e=>{
            updateSnackStatus({
                open:true,
                message:'Erro ao carregar modelos, atualize a página.',
                severity:'error',
            });
        });
    }

       
}

export function ModelsGalery({images,configs,modelsid,cols,imgWidth,onModelSelected}){
      return (
        <div style={{textAlign:'center'}}>
           <Galery imageSelected={(i,ref)=>{onModelSelected(ref)}} refs={modelsid} cols={cols} configs={configs} images={images} width={(imgWidth+10)*cols}/>
        </div>
      )
      
}