import { sendServerRequest } from "../utils/sendservr";
export async function getUserModels(){
   try{
      if(!window.userModels){
        const result=await sendServerRequest('/server/models/get');   
        const {data}=result.data;
        window.userModels=data.models;
        for(let i=0;i<window.userModels.length;i++){
            window.userModels[i].script=JSON.parse(window.userModels[i].script);
        }
      }
      return window.userModels;
   }catch(e){
      throw e;
   }
}
export async function resetUserModels(){
     window.userModels=null;
}

async function getModelInsertConfigs(id){

   if(!window.userModelsData)
      window.userModelsData={};
   if(!window.userModelsData[id]){
       const result=await sendServerRequest(`/server/models/get/configs/${id}`);
       const {data}=result.data;
       data.atConfig=serverConfigToFEConfig(data.atConfig);
       for(let i=0;i<data.configs.length;i++){
          data.configs[i]=serverConfigToFEConfig(data.configs[i]);
       }
       window.userModelsData[id]=data;
   }
   return window.userModelsData[id];
   function serverConfigToFEConfig(cf){
        return{
           xs:cf.xs,
           ys:cf.ys,
           maxWidth:cf.max_width,
           maxHeight:cf.max_height,
           font:{
              family:cf.font_name,
              style:cf.font_color,
              adjust_size:true,
           },
           alignMode:cf.align_mode,
           centerHeight:cf.center_height,
        }
   }
}

export async function getModelsByPostScript(postScript){
    try{
       const models=await getUserModels();
       let ret=[];
       for(let model of models){
          if(!postScript||model.post_script==postScript){
              ret.push(await getModelInsertConfigs(model.id));
          }
       }
       return ret;
    }catch(e){
      throw e;
    }
}

export function getModelImageExempleConfig(model){
   try{
       const images=[];
       const configs=[];
       configs.push(getConfigs(model));
       for(let img of model.images){
          images.push({id:img});
       };
       return {
         images,
         configs,
         postScript:model.post_script,
         id:model.id,
       }
   }catch(e){
       throw e;
   }

   function getConfigs(m){
      let ret=[];
      if(m.atConfig){
         let at={};
         Object.assign(at,m.atConfig);
         ret.push({
           config:at,
           text:'@username',
         });
      }
      if(m.configs){
        let t={};
        Object.assign(t,m.configs[0]);
        ret.push({
           config:t,
           text:'Título da postagem.'
        });
      }
      for(let i=1;i<m.configs.length;i++){
          let c1={};
          Object.assign(c1,m.configs[i]);
          ret.push({
             config:c1,
             text:`Check item número ${i}`,
          });
      }
      return ret;
 }
}

export async function getModelsImageConfigsByType(postScript){
   try{
       let ret=[];
       const models=await getModelsByPostScript(postScript);
       for(let model of models){
          ret.push(
            getModelImageExempleConfig(model),
          )
       }
       return ret;
   }catch(e){
       throw e;
   }
}