import { Divider, Icon } from "@mui/material";
import {Typography} from "@mui/material";
import terms from '../css/terms.css';
import PeopleIcon from '@mui/icons-material/People';
import { Person as PersonIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
export function PrivacyPoliticalSimple(props){
   const {site,location,email,toComplete,fbPermissions}=props;
   return(
      <div className="terms">
      <PrivacyTitle site={site}/>
      <Divider/>

      <div className="term_block">
         <Typography component='h2' sx={{fontSize:"18px",fontWeight:1000}}>Os Dados Pessoais são processados para os seguintes propósitos e usando os seguintes serviços:</Typography>
         <TextWithIcon icon={<PeopleIcon/>} text={"Acesso a contas de serviços de terceiros"}/>
         <Typography sx={{fontSize:"15px",fontWeight:700,marginTop:'15px',color:"#000000bd"}}>Acesso à conta Facebook</Typography>
         <Typography className="content">{fbPermissions}</Typography>
      </div>
      <Divider/>
      <PrivacyContact email={email} location={location}/>
      <Divider/>
      <div className="term_block">
      <Typography sx={{fontSize:"10px",color:"#000000bd"}}>{`Última atualização: ${getDate()}`}</Typography>
      </div>
      <div className="term_block" style={{textAlign:"right"}}>
      <Link to={toComplete||"/app/privacy"}><Typography sx={{fontSize:"13px",color:"#000000bd",textDecoration:"underline",cursor:"pointer"}}>{`Mostrar a política de privacidade completa`}</Typography></Link>
      </div>
      </div>
   )
   function getDate(){
       return `1 de maio de 2023`;
   }    
}

export function TypeOfData(){
    return (
        <div className="term_block">
            <h3 className="subtitle">Tipos de Dados Coletados</h3>
            <p className="content">Uso de Dados e outros dados coletados</p>
            <p className="content">Detalhes completos sobre cada tipo de Dados Pessoais coletados são fornecidos nas seções dedicadas desta política de privacidade ou por textos explicativos específicos exibidos antes da coleta de Dados.
                        Os Dados Pessoais poderão ser fornecidos livremente pelo Usuário, ou, no caso dos Dados de Utilização, coletados automaticamente ao se utilizar este Aplicativo.
                        A menos que especificado diferentemente todos os Dados solicitados por este Aplicativo são obrigatórios e a falta de fornecimento destes Dados poderá impossibilitar este Aplicativo de fornecer os seus Serviços. Nos casos em que este Aplicativo afirmar especificamente que alguns Dados não forem obrigatórios, os Usuários ficam livres para deixarem de comunicar estes Dados sem nenhuma consequência para a disponibilidade ou o funcionamento do Serviço.
                        Os Usuários que tiverem dúvidas a respeito de quais Dados Pessoais são obrigatórios estão convidados a entrar em contato com o Proprietário.
                        Quaisquer usos de cookies – ou de outras ferramentas de rastreamento – por este Aplicativo ou pelos proprietários de serviços terceiros utilizados por este Aplicativo serão para a finalidade de fornecer os Serviços solicitados pelo Usuário, além das demais finalidades descritas no presente documento e na Política de Cookies, se estiver disponível.
            </p>
            <p className="content">
            Os Usuários ficam responsáveis por quaisquer Dados Pessoais de terceiros que forem obtidos, publicados ou compartilhados através deste Serviço (este Aplicativo) e confirmam que possuem a autorização dos terceiros para fornecerem os Dados para o Proprietário.
            </p>
 
        </div>
    )
}
export function PrivacyPolitical(props){
    const {site,location,email,toSimple,fbPermissions}=props;
    return(
        <div className="terms">
             <PrivacyTitle site={site}/>
             <Divider/>
             <PrivacyContact email={email} location={location}/>
             <Divider/>
             <TypeOfData/>
             <Divider/>
             <ModeAndLocalProcess/>
             <Divider/>
            <div className="term_block">
             <p className="subtitle">As finalidades do processamento</p>
             <p className="content">Os Dados relativos ao Usuário são coletados para permitir que o Proprietário preste seu Serviço, cumpra suas obrigações legais, responda a solicitações de execução, proteja seus direitos e interesses (ou aqueles de seus Usuários ou terceiros), detecte qualquer atividade maliciosa ou fraudulenta, assim como: Acesso a contas de serviços de terceiros.</p>
             <p className="content">Para obter informações específicas sobre os Dados Pessoais utilizados para cada finalidade, o Usuário poderá consultar a seção "Informações detalhadas sobre o processamento de Dados Pessoais".</p>
             </div>
             <Divider/>
             <div className="term_block">
                <p className="subtitle">Permissões do Facebook solicitadas por este Aplicativo</p>
                <p className="content">Este Aplicativo pode pedir algumas permissões no Facebook que permitem realizar ações com a conta do usuário no Facebook e para recuperar as informações, incluindo dados pessoais. Este serviço permite que este Aplicativo se conecte com a conta do Usuário na rede social Facebook, fornecido pelo Facebook Inc.</p>
                <p className="content">Para mais informações sobre as seguintes permissões, consulte a <Link target="_blank" to="https://developers.facebook.com/docs/permissions/reference">documentação de permissões Facebook</Link> e a <Link target="_blank" to="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">política de privacidade do Facebook.</Link></p>
                <p className="content">{fbPermissions}</p>
             </div>
             <Divider/>
             <div className="term_block">
                <p className="subtitle">Informações detalhadas sobre o processamento de Dados Pessoais</p>
                <p className="content">Os Dados Pessoais são recolhidos para os seguintes fins e utilizando os seguintes serviços:</p>
                <p className="content strong no_margin_bottom">Acesso a contas de serviços de terceiros</p>
                <p className="content no_margin_top">Este tipo de serviço permite a este Aplicativo acessar os Dados da sua conta com um serviço terceiro e realizar ações com os mesmos.
Estes serviços não são ativados automaticamente, precisam de autorização explícita do Usuário.</p>
                <p className="content strong no_margin_bottom">Acesso à conta Facebook</p>
                <p className="content no_margin_top">Este serviço permite que este Aplicativo se conecte com a conta do Usuário na rede social Facebook, fornecido pelo Meta Platforms, Inc. ou pela Meta Platforms Ireland Limited, dependendo de como o Proprietário gerencia o processamento de dados.</p>
                <p className="content">{fbPermissions}</p>
                <p className="content">Lugar de processamento: EUA – <Link target="_blank" to="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Política de Privacidade;</Link> Irlanda – <Link target="_blank" to="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0">Política de Privacidade.</Link></p>
             </div>
             <Divider/>
             <UserRights/>
             <Divider/>
             <CookiesPolicyLink/>
             <Divider/>
             <AddInfo/>
             <Divider/>
             <LegalDefinition/>
             <Divider/>
             <div className="term_block" style={{textAlign:"right"}}>
            <Link to={toSimple||"/app/privacy/simple"}><Typography sx={{fontSize:"13px",color:"#000000bd",textDecoration:"underline",cursor:"pointer"}}>{`Mostrar a política de privacidade simplificada`}</Typography></Link>
            </div>
        </div>
    )
}    

function TextWithIcon(props){
    const {icon,text}=props;
    return (
        <div style={{display:"flex",marginTop:"10px",color:"#000000bd",alignItems:"flex-start"}}>
           <Icon>{icon}</Icon>
           <Typography component='p' sx={{marginLeft:"5px",fontSize:"15px"}}>{text}</Typography>
        </div>
    )

}
function PrivacyTitle(props){
    const {site}=props;
    return (
        <div>
        <Typography component='h1' sx={{fontSize:"22px",textAlign:"left"}}>{`Política de Privacidade do `}<Typography variant="span" sx={{fontWeight:"1000"}}>{`${site}`}</Typography></Typography>
        <Typography component='p' sx={{fontSize:"12px",textAlign:"left",color:"#000000bd"}}>Este Aplicativo recolhe alguns Dados Pessoais dos Usuários</Typography>
      </div>
    )
}

function PrivacyContact(props){
    const {email,location}=props;
    return(
        <div className="term_block">
         <p component='h2' className="subtitle">Informação de contato</p>
         <TextWithIcon icon={<PersonIcon/>} text={"Proprietário e Controlador de Dados"}/>
         <Typography sx={{fontSize:"15px",color:"#0000009f",marginTop:"8px"}}>{location}</Typography>
         <Typography sx={{fontSize:"15px",fontWeight:700,marginTop:'15px',color:"#000000bd"}}>E-mail de contato do Proprietário:</Typography>
         <Typography sx={{fontSize:"14px",color:"#000000bd"}}>{email}</Typography>
      </div>
    )
}

function ModeAndLocalProcess(){
    return (
        <div className="term_block">
        <h2 className="subtitle">Modo e local de processamento dos Dados</h2>
        <p className="content strong no_margin_bottom">Método de processamento</p>
        <p className="content no_margin_top">O Proprietário tomará as medidas de segurança adequadas para impedir o acesso não autorizado, divulgação, alteração ou destruição não autorizada dos Dados.
O processamento dos Dados é realizado utilizando computadores e /ou ferramentas de TI habilitadas, seguindo procedimentos organizacionais e meios estritamente relacionados com os fins indicados. Além do Proprietário, em alguns casos, os Dados podem ser acessados por certos tipos de pessoas encarregadas, envolvidas com a operação deste Serviço (este Aplicativo) (administração, vendas, marketing, administração legal do sistema) ou pessoas externas (como fornecedores terceirizados de serviços técnicos, carteiros, provedores de hospedagem, empresas de TI, agências de comunicação) nomeadas, quando necessário, como Processadores de Dados por parte do Proprietário. A lista atualizada destas partes pode ser solicitada ao Proprietário a qualquer momento.</p>
        <p className="content strong no_margin_bottom">Base jurídica para o processamento</p>
        <p className="content no_margin_top">O Proprietário poderá processar os Dados Pessoais relacionados ao Usuário se uma das hipóteses a seguir se aplicar:</p>
        <p className="content list_item zero_margin">os Usuários tenham dado a sua anuência para uma ou mais finalidades específicas; Observação: De acordo com algumas legislações o Proprietário poderá ter a permissão para processar os Dados Pessoais ATÉ QUE O Usuário faça objeção a isto (“opt-out”), sem ter que se basear em anuência ou em quaisquer outras bases jurídicas a seguir. Isto contudo não se aplica sempre que o processamento de Dados Pessoais estiver sujeito à legislação europeia de proteção de dados;</p>
        <p className="content list_item zero_margin">o fornecimento dos Dados for necessário para o cumprimento de um contrato com o Usuário e/ou quaisquer obrigações pré-contratuais do mesmo;</p>
        <p className="content list_item zero_margin">o processamento for necessário para o cumprimento de uma obrigação jurídica à qual o Proprietário estiver sujeito;</p>
        <p className="content list_item zero_margin">o processamento estiver relacionado a uma tarefa que for executada no interesse público ou no exercício de uma autorização oficial na qual o Proprietário estiver investido;</p>
        <p className="content list_item zero_margin">o processamento for necessário para a finalidade de interesses legítimos perseguidos pelo Proprietário ou por um terceiro;</p>
        <p className="content">Em qualquer caso, o Proprietário colaborará de bom grado para esclarecer qual a base jurídica que se aplica ao processamento, e em especial se o fornecimento de Dados for um requisito obrigatório por força de lei ou contratual, ou uma exigência necessária para celebrar um contrato.</p>
        <p className="content strong no_margin_bottom">Lugar</p>
        <p className="content no_margin_top">Os dados são processados ​​nas sedes de operação dos Proprietários, e em quaisquer outros lugares onde as partes envolvidas com o processamento estiverem localizadas.
Dependendo da localização do Usuário as transferências de dados poderão envolver a transferência dos Dados do Usuário para outro país que não seja o seu. Para descobrirem mais sobre o local de processamento de tais Dados transferidos os Usuários poderão verificar a seção contendo os detalhes sobre o processamento de Dados Pessoais.</p>
        <p className="content">Os Usuários também possuem o direito de serem informados sobre a base jurídica das transferências de Dados para países de fora da União Europeia ou para quaisquer organizações internacionais regidas pelo direito internacional público ou formadas por dois ou mais países, tal como a ONU, e sobre as medidas de segurança tomadas pelo Proprietário para proteger os seus Dados.</p>
        <p className="content">
Se ocorrerem quaisquer tais transferências os Usuários poderão descobrir mais a respeito verificando as seções pertinentes deste documento ou perguntando ao Proprietário utilizando as informações fornecidas na seção de contatos.</p>
        <p className="content strong no_margin_bottom">Período de conservação</p>
        <p className="content no_margin_top">Os Dados Pessoais serão processados e armazenados pelo tempo que for necessário para as finalidades para as quais forem coletados.</p>
        <p className="content">Portanto:</p>
        <p className="content list_item no_margin_bottom">Os Dados Pessoais coletados para as finalidades relacionadas com a execução de um contrato entre o Proprietário e o Usuário serão conservados até que tal contrato tenha sido completamente cumprido.</p>
        <p className="content list_item no_margin_top">Os Dados Pessoais coletados para as finalidades relacionadas com os legítimos interesses do Proprietário serão conservados pelo tempo que for necessário para cumprir tais finalidades. Os Usuários poderão obter informações específicas sobre os interesses legítimos perseguidos pelo Proprietário dentro das seções pertinentes deste documento ou entrando em contato com o Proprietário.</p>
        <p className="content">O Proprietário poderá ter a permissão de conservar os Dados Pessoais por um prazo maior sempre que o Usuário tiver dado a sua autorização para tal processamento, enquanto tal autorização não tiver sido retirada. Além disso, o Proprietário poderá ficar obrigado a conservar os Dados Pessoais por um prazo maior em todas as ocasiões em que estiver obrigado a fazê-lo para o cumprimento de uma obrigação jurídica ou em cumprimento de um mandado de uma autoridade.</p>
        <p className="content">Assim que o prazo de conservação vencer os Dados Pessoais serão apagados. Desta forma o direito de acessar, o direito de apagar, o direito de corrigir e o direito à portabilidade dos dados não poderão ter o seu cumprimento exigido após o vencimento do prazo de conservação.</p>
        </div>
    )
}


function UserRights(){
    return (
        <div className="term_block">
           <p className="subtitle">Os direitos dos Usuários</p>
           <p className="content">Os Usuários poderão exercer determinados direitos a respeito dos seus Dados processados pelo Proprietário.</p>
           <p className="content">Em especial, os Usuários possuem os direitos a fazer o seguinte, dentro dos limites permitidos por lei:</p>
           <p className="content list_item no_margin_bottom"><span className="strong">Retirar a sua anuência a qualquer momento.</span> Os Usuários possuem o direito de retirar a sua anuência nos casos em que tenham dado a sua anuência anteriormente para o processamento dos seus Dados Pessoais.</p>
           <p className="content list_item zero_margin"><span className="strong">Objetar o processamento dos seus Dados.</span> Os Usuários possuem o direito de objetar o processamento dos seus Dados se o processamento for executado sobre outra base jurídica que não a anuência. São fornecidos detalhes adicionais na seção específica abaixo.</p>
           <p className="content list_item zero_margin"><span className="strong">Acessar os seus Dados.</span> Os Usuários possuem o direito de saber se os seus Dados estão sendo tratados pelo Proprietário, obter revelações sobre determinados aspectos do tratamento e conseguir uma cópia dos Dados que estiverem sendo tratados.</p>
           <p className="content list_item zero_margin"><span className="strong">Verificar e pedir retificação.</span> Os Usuários possuem o direito de verificar a exatidão dos seus Dados e de pedir que os mesmos sejam atualizados ou corrigidos.</p>
           <p className="content list_item zero_margin"><span className="strong">Restringir o processamento dos seus Dados.</span> Os Usuários possuem o direito de restringir o processamento dos seus Dados para qualquer outra finalidade que não seja o armazenamento dos mesmos.</p>
           <p className="content list_item zero_margin"><span className="strong">Ter os seus Dados Pessoais apagados ou retirados de outra maneira.</span> Os Usuários possuem o direito de obter a eliminação dos seus Dados do Proprietário.</p>
           <p className="content list_item zero_margin"><span className="strong">Receber os seus Dados e ter os mesmos transferidos para outro controlador.</span> Os Usuários possuem o direito de receber os seus Dados em um formato estruturado, utilizado comumente e apto a ser lido por máquinas e, se for viável tecnicamente, fazer com que estes sejam transmitidos para outro controlador sem nenhum empecilho.</p>
           <p className="content list_item zero_margin"><span className="strong">Registrar uma reclamação.</span> Os Usuários possuem o direito de apresentar reclamação perante a sua autoridade de proteção de dados competente.</p>
           <p className="content strong no_margin_bottom">Detalhes sobre o direito de objetar ao processamento</p>
           <p className="content no_margin_top">Nos casos em que os Dados Pessoais forem processados por um interesse público, no exercício de uma autorização oficial na qual o Proprietário estiver investido ou para finalidades dos interesses legítimos perseguidos pelo Proprietário, os Usuários poderão objetar tal processamento através do fornecimento de um motivo relacionado com a sua situação em especial para justificar a objeção.</p>
           <p className="content">Os Usuários devem saber, entretanto, que caso os seus Dados Pessoais sejam processados para finalidades de marketing direto, eles podem objetar tal processamento a qualquer momento de forma gratuita e sem fornecer qualquer justificativa. Quando o Usuário se opuser ao processamento para fins de marketing direto, os Dados Pessoais não serão mais processados para esses fins. Para saber se o Proprietário está processando Dados Pessoais para fins de marketing direto, os Usuários podem consultar as seções respectivas deste documento.</p>
           <p className="content strong no_margin_bottom">Como exercer estes direitos</p>
           <p className="content no_margin_top">Quaisquer pedidos para exercer os direitos dos Usuários podem ser direcionados ao Proprietário através dos dados para contato fornecidos neste documento. Estes pedidos podem ser exercidos sem nenhum custo e serão atendidos pelo Proprietário com a maior brevidade possível e, em todos os casos, em prazo inferior a um mês, fornecendo aos Usuários as informações exigidas por lei. Qualquer retificação ou exclusão de Dados Pessoais ou restrição de processamento será comunicada pelo Proprietário a cada destinatário, se houver, a quem os Dados Pessoais foram divulgados, a menos que isso seja impossível ou implique um trabalho desproporcional. A pedido dos Usuários, o Proprietário os informará sobre tais destinatários.</p>
        </div>
    )
}


function CookiesPolicyLink(){
    return (
        <div className="term_block">
            <p className="subtitle">Política de Cookies</p>
            <p className="content">Este Aplicativo usa Rastreadores. Para saber mais, o Usuário poderá consultar a <Link to='/app/cookie-policy'>Política de Cookies.</Link></p>

        </div>
    )
}

function AddInfo(){
    return(
        <div className="term_block">
            <p className="subtitle">Informações adicionais sobre a coleta e processamento de Dados</p>
            <p className="content strong no_margin_bottom">Ação jurídica</p>
            <p className="content no_margin_top">Os Dados Pessoais dos Usuários podem ser utilizados para fins jurídicos pelo Proprietário em juízo ou nas etapas conducentes à possível ação jurídica decorrente de uso indevido deste Serviço (este Aplicativo) ou dos Serviços relacionados.
O Usuário declara estar ciente de que o Proprietário poderá ser obrigado a revelar os Dados Pessoais mediante solicitação das autoridades governamentais.</p>
            <p className="content strong no_margin_bottom">Informações adicionais sobre os Dados Pessoais do Usuário</p>
            <p className="content no_margin_top">Além das informações contidas nesta política de privacidade, este Aplicativo poderá fornecer ao Usuário informações adicionais e contextuais sobre os serviços específicos ou a coleta e processamento de Dados Pessoais mediante solicitação.</p>
            <p className="content strong no_margin_bottom">Logs do sistema e manutenção</p>
            <p className="content no_margin_top">Para fins de operação e manutenção, este Aplicativo e quaisquer serviços de terceiros poderão coletar arquivos que gravam a interação com este Aplicativo (logs do sistema) ou usar outros Dados Pessoais (tais como endereço IP) para esta finalidade.</p>
            <p className="content strong no_margin_bottom">As informações não contidas nesta política</p>
            <p className="content no_margin_top">Mais detalhes sobre a coleta ou processamento de Dados Pessoais podem ser solicitados ao Proprietário, a qualquer momento. Favor ver as informações de contato no início deste documento.</p>
            <p className="content strong no_margin_bottom">Como são tratados os pedidos de “Não me Rastreie”</p>
            <p className="content no_margin_top">Este Aplicativo não suporta pedidos de “Não Me Rastreie”.
Para determinar se qualquer um dos serviços de terceiros que utiliza honram solicitações de “Não Me Rastreie”, por favor leia as políticas de privacidade.</p>
            <p className="content strong no_margin_bottom">Mudanças nesta política de privacidade</p>
            <p className="content no_margin_top">O Proprietário se reserva o direito de fazer alterações nesta política de privacidade a qualquer momento, através de notificação a seus Usuários nesta página e possivelmente dentro deste Serviço (este Aplicativo) e/ou – na medida em que for técnica e juridicamente viável – enviando um aviso para os Usuários através de quaisquer informações de contato disponíveis para o Proprietário. É altamente recomendável checar esta página regularmente, consultando a data da última modificação informada na parte inferior.</p>
            <p className="content">Caso as mudanças afetem as atividades de processamento realizadas com base na anuência do Usuário, o Proprietário coletará nova anuência do Usuário, onde for exigida.</p>
        </div>
    )
}

function LegalDefinition(){
    return (
        <div className="term_block">
            <h3 className="subtile">Definições e referências jurídicas</h3>
            <p className="content strong no_margin_bottom">Dados Pessoais (ou Dados)</p>
            <p className="content no_margin_top">Quaisquer informações que diretamente, indiretamente ou em relação com outras informações – incluindo um número de identificação pessoal – permitam a identificação ou identificabilidade de uma pessoa física.</p>
            <p className="content strong no_margin_bottom">Dados de Uso</p>
            <p className="content no_margin_top">As informações coletadas automaticamente através deste este Aplicativo (ou serviços de terceiros contratados neste Serviço (este Aplicativo)), que podem incluir: os endereços IP ou nomes de domínio dos computadores utilizados pelos Usuários que utilizam este Aplicativo, os endereços URI (Identificador Uniforme de Recurso), a data e hora do pedido, o método utilizado para submeter o pedido ao servidor, o tamanho do arquivo recebido em resposta, o código numérico que indica o status do servidor de resposta (resultado positivo, erro , etc.), o país de origem, as características do navegador e do sistema operacional utilizado pelo Usuário, os vários detalhes de tempo por visita (por exemplo, o tempo gasto em cada página dentro do aplicativo) e os detalhes sobre o caminho seguido dentro da aplicação, com especial referência à sequência de páginas visitadas e outros parâmetros sobre o sistema operacional do dispositivo e/ou ambiente de TI do Usuário.</p>
            <p className="content strong no_margin_bottom">Usuário</p>
            <p className="content no_margin_top">A pessoa que usa este Aplicativo que, a menos que especificado diferentemente, coincida com o Titular dos Dados.</p>
            <p className="content strong no_margin_bottom">Titular dos Dados</p>
            <p className="content no_margin_top">A pessoa física a quem os Dados Pessoais se referem.</p>
            <p className="content strong no_margin_bottom">Processador de Dados (ou supervisor de Dados)</p>
            <p className="content no_margin_top">A pessoa física ou jurídica, administração pública, agência ou outro órgão que processe os Dados Pessoais em nome do Controlador conforme descrito nesta política de privacidade.</p>
            <p className="content strong no_margin_bottom">Controlador de Dados (ou Proprietário)</p>
            <p className="content no_margin_top">A pessoa física ou jurídica, administração pública, agência ou outro órgão que, isoladamente ou em conjunto com outros determinar as finalidades e os meios de processamento dos Dados Pessoais, incluindo medidas de segurança relativas ao funcionamento e ao uso deste Serviço (este Aplicativo). O Controlador de Dados, a menos que seja especificado de outra forma, é o Proprietário deste Serviço (este Aplicativo).</p>
            <p className="content strong no_margin_bottom">Este Aplicativo</p>
            <p className="content no_margin_top">O meio pelo qual os Dados Pessoais do Usuário são coletados e processados.</p>
            <p className="content strong no_margin_bottom">Serviço</p>
            <p className="content no_margin_top">O serviço fornecido por este Aplicativo conforme descrito nos termos relativos (se disponíveis) e neste site/aplicativo.</p>
            <p className="content strong no_margin_bottom">União Europeia (ou UE)</p>
            <p className="content no_margin_top">A menos que especificado diferentemente, todas as referências feitas neste documento à União Europeia incluem todos os atuais estados membros da União Europeia e do Espaço Econômico Europeu.</p>
            <Divider/>
            <p className="content strong no_margin_bottom">Informação jurídica</p>
            <p className="content no_margin_top">Esta declaração de privacidade foi preparada com base em determinações de múltiplas legislações, inclusive os Arts. 13/14 do Regulamento (EU) 2016/679 (GDPR - Regulamento Geral de Proteção de Dados).</p>
            <p className="content">Esta política de privacidade se refere somente a este Aplicativo, se não afirmado diferentemente neste documento.</p>
        </div>
    )
}

export function CookiesPolicy(props){
    const {site,email,location,toComplete}=props;
    return (
        <div className="terms">
             <div className="term_block">
              <Typography component='h1' sx={{fontSize:"22px",textAlign:"left",fontWeight:"900"}}>Política de Cookies do {site}</Typography>
              <p className="content">Este documento informa os Usuários sobre as tecnologias que ajudam este Aplicativo a alcançar as finalidades descritas abaixo. Tais tecnologias permitem ao Proprietário acessar e armazenar informações (por exemplo, usando um Cookie) ou usar recursos (por exemplo, executando um script) em um dispositivo de um Usuário à medida que interagem com este Aplicativo.</p>
              <p className="content">Para fins de simplicidade, todas estas tecnologias são definidas como "Rastreadores" neste documento – a menos que haja um motivo para diferenciá-las.
                Por exemplo, enquanto Cookies podem ser usados em navegadores de web e dispositivos móveis, seria incorreto falar sobre Cookies no contexto de aplicativos móveis, pois eles são um rastreador baseado em um navegador. Por este motivo, neste documento, o termo Cookies somente é usado onde ele especificamente indicar aquele tipo específico de Rastreador.
                </p>
              <p className="content">Algumas das finalidades para as quais Rastreadores são usados também podem exigir a permissão do Usuário. Sempre que a permissão for dada, ela pode ser livremente retirada a qualquer momento, seguindo as instruções fornecidas neste documento.</p>  
              <p className="content">Este Aplicativo usa apenas Rastreadores gerenciados diretamente pelo Proprietário (conhecidos como Rastreadores "próprios").</p>
              <p className="content">Os períodos de validade e expiração dos Cookies próprios e outros Rastreadores similares poderão variar, dependendo do prazo estabelecido pelo Proprietário. Alguns deles expiram quando do término da sessão de navegação do Usuário.</p>
              <h3 className="subtitle">Atividades estritamente necessárias para operar este Aplicativo e prestar o Serviço</h3>
              <p className="content">Este Aplicativo usa Cookies conhecidos como Cookies "técnicos" e outros Rastreadores similares para executar atividades que são estritamente necessárias para operar ou prestar o Serviço.</p>
              <p className="subtitle">Como gerenciar preferências e fornecer ou cancelar a permissão</p>
              <p className="content">Há diversas maneiras de gerenciar preferências relacionadas a Rastreadores e de fornecer e cancelar a permissão, onde for relevante:</p>
              <p className="content">Os Usuários podem gerenciar preferências relacionadas a Rastreadores diretamente a partir das configurações de seu próprio dispositivo, por exemplo, impedindo o uso ou armazenagem de Rastreadores.</p>
              <p className="content">Além disso, sempre que o uso de Rastreadores tiver por base uma permissão, os Usuários poderão fornecer ou cancelar tal permissão, configurando suas preferências no aviso de cookies ou atualizando tais preferências de acordo com o respectivo widget de preferências de permissão, se houver.</p>
              <p className="content">Também é possível, através dos respectivos recursos do navegador ou dispositivo, excluir Rastreadores previamente armazenados, incluindo aqueles usados para lembrar a permissão inicial do Usuário.</p>
              <p className="content">Outros Rastreadores na memória local do navegador podem ser apagados ao excluir o histórico de navegação.</p>
              <p className="content strong">Configurações de Rastreadores de localização</p>
              <p className="content">Os Usuários poderão, por exemplo, encontrar informações sobre como gerenciar Cookies nos navegadores mais usados nos seguintes endereços:</p>
              <p className="content list_item no_margin_bottom"><Link to="https://support.google.com/chrome/answer/95647?hl=pt-BR&p=cpn_cookies" target="_blank">Google Chrome</Link></p>
              <p className="content list_item no_margin"><Link to="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam" target="_blank">Mozilla Firefox</Link></p>
              <p className="content list_item no_margin"><Link to="https://support.apple.com/pt-br/guide/safari/manage-cookies-and-website-data-sfri11471/" target="_blank">Apple Safari</Link></p>
              <p className="content list_item no_margin"><Link to="http://windows.microsoft.com/pt-br/windows-vista/block-or-allow-cookies" target="_blank">Microsoft Internet Explorer</Link></p>
              <p className="content list_item no_margin"><Link to="https://support.microsoft.com/pt-br/help/4027947" target="_blank">Microsoft Edge</Link></p>
              <p className="content list_item no_margin"><Link to="https://support.brave.com/hc/articles/360022806212-How-do-I-use-Shields-while-browsing" target="_blank">Brave</Link></p>
              <p className="content list_item no_margin_top"><Link to="https://help.opera.com/latest/web-preferences/#cookies" target="_blank">Opera</Link></p>
              <p className="content">Os Usuários também poderão gerenciar determinadas categorias de Rastreadores usados em aplicativos móveis, optando pelo não uso através das respectivas configurações do dispositivo, tais como as configurações de publicidade para dispositivos móveis ou configurações de rastreamento em geral (os Usuários poderão abrir as configurações do dispositivo e procurar as respectivas configurações).</p>
              <p className="content strong">Consequências de negar consentimento</p>
              <p className="content">Os usuários podem decidir livremente se concedem ou não consentimento. No entanto, é importante observar que os Rastreadores ajudam este Aplicativo a fornecer uma melhor experiência e funcionalidades avançadas aos Usuários (de acordo com as finalidades descritas neste documento). Portanto, na ausência do consentimento do Usuário, o Proprietário pode não conseguir fornecer os recursos relacionados.</p>
              </div>
              <Divider/>
              <LegalDefinitionCookie/>
              <Divider/>
              <PrivacyContact email={email} location={location}/>
              <div className="term_block" style={{textAlign:"right"}}>
                <Link to={toComplete||"/app/privacy"}><Typography sx={{fontSize:"13px",color:"#000000bd",textDecoration:"underline",cursor:"pointer"}}>{`Mostrar a política de privacidade completa`}</Typography></Link>
              </div>
        </div>
    )
}


function LegalDefinitionCookie(){
    return (
        <div className="term_block">
            <h3 className="subtile">Definições e referências jurídicas</h3>
            <p className="content strong no_margin_bottom">Dados Pessoais (ou Dados)</p>
            <p className="content no_margin_top">Quaisquer informações que diretamente, indiretamente ou em relação com outras informações – incluindo um número de identificação pessoal – permitam a identificação ou identificabilidade de uma pessoa física.</p>
            <p className="content strong no_margin_bottom">Dados de Uso</p>
            <p className="content no_margin_top">As informações coletadas automaticamente através deste este Aplicativo (ou serviços de terceiros contratados neste Serviço (este Aplicativo)), que podem incluir: os endereços IP ou nomes de domínio dos computadores utilizados pelos Usuários que utilizam este Aplicativo, os endereços URI (Identificador Uniforme de Recurso), a data e hora do pedido, o método utilizado para submeter o pedido ao servidor, o tamanho do arquivo recebido em resposta, o código numérico que indica o status do servidor de resposta (resultado positivo, erro , etc.), o país de origem, as características do navegador e do sistema operacional utilizado pelo Usuário, os vários detalhes de tempo por visita (por exemplo, o tempo gasto em cada página dentro do aplicativo) e os detalhes sobre o caminho seguido dentro da aplicação, com especial referência à sequência de páginas visitadas e outros parâmetros sobre o sistema operacional do dispositivo e/ou ambiente de TI do Usuário.</p>
            <p className="content strong no_margin_bottom">Usuário</p>
            <p className="content no_margin_top">A pessoa que usa este Aplicativo que, a menos que especificado diferentemente, coincida com o Titular dos Dados.</p>
            <p className="content strong no_margin_bottom">Titular dos Dados</p>
            <p className="content no_margin_top">A pessoa física a quem os Dados Pessoais se referem.</p>
            <p className="content strong no_margin_bottom">Processador de Dados (ou supervisor de Dados)</p>
            <p className="content no_margin_top">A pessoa física ou jurídica, administração pública, agência ou outro órgão que processe os Dados Pessoais em nome do Controlador conforme descrito nesta política de privacidade.</p>
            <p className="content strong no_margin_bottom">Controlador de Dados (ou Proprietário)</p>
            <p className="content no_margin_top">A pessoa física ou jurídica, administração pública, agência ou outro órgão que, isoladamente ou em conjunto com outros determinar as finalidades e os meios de processamento dos Dados Pessoais, incluindo medidas de segurança relativas ao funcionamento e ao uso deste Serviço (este Aplicativo). O Controlador de Dados, a menos que seja especificado de outra forma, é o Proprietário deste Serviço (este Aplicativo).</p>
            <p className="content strong no_margin_bottom">Este Aplicativo</p>
            <p className="content no_margin_top">O meio pelo qual os Dados Pessoais do Usuário são coletados e processados.</p>
            <p className="content strong no_margin_bottom">Serviço</p>
            <p className="content no_margin_top">O serviço fornecido por este Aplicativo conforme descrito nos termos relativos (se disponíveis) e neste site/aplicativo.</p>
            <p className="content strong no_margin_bottom">União Europeia (ou UE)</p>
            <p className="content no_margin_top">A menos que especificado diferentemente, todas as referências feitas neste documento à União Europeia incluem todos os atuais estados membros da União Europeia e do Espaço Econômico Europeu.</p>
            <p className="content strong no_margin_bottom">Cookie</p>
            <p className="content no_margin_top">Cookies são Rastreadores compostos por pequenos conjuntos de dados armazenados no navegador do Usuário.</p>
            <p className="content strong no_margin_bottom">Rastreador</p>
            <p className="content no_margin_top">O termo Rastreador indica qualquer tecnologia – como Cookies, identificadores únicos, web beacons, scripts embutidos, e-tags e fingerprinting – que permita o rastreamento de Usuários, por exemplo, acessando ou a armazenando informações no dispositivo do Usuário.</p>
            <Divider/>
            <p className="content strong no_margin_bottom">Informação jurídica</p>
            <p className="content no_margin_top">Esta declaração de privacidade foi preparada com base em determinações de múltiplas legislações, inclusive os Arts. 13/14 do Regulamento (EU) 2016/679 (GDPR - Regulamento Geral de Proteção de Dados).</p>
            <p className="content">Esta política de privacidade se refere somente a este Aplicativo, se não afirmado diferentemente neste documento.</p>
            
        </div>
    )
}