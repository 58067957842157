import './App.css';
import SignIn from './components/login';
import { getModelInsertConfigs } from './global/models';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Dashboard } from './components/dashboards';
import React, { useEffect } from 'react';
import { PrivacyPoliticalSimple,PrivacyPolitical } from './components/privacy';
import { CookiesPolicy } from './components/privacy';
import { Link } from 'react-router-dom';
import { redirectToLoginPage } from './utils/redirects';
import { Snack } from './components/snack';
const location="R. Frei Rogerio, 858 - Piso Superior - Centro, Lages - SC, 88502-161";
const email="postgenerator@proton.me";
const fbPermissions="Permissão: API de gerenciamento de negócios; E-mail; Gerenciar Anúncios; Informações básicas do Instagram; Páginas leem o engajamento;Gerenciamento do processo de criação e postagem de conteúdo orgânico do Instagram";

function Home(){
  useEffect(()=>{
     redirectToLoginPage();
  })
  return (
    <div>
        <h3>APP EM DESENOLVIMENTO</h3>
        <Link to='/app/privacy'>Politica de privacidade</Link>
    </div>
  )
}
function Error404(){
   return(
    <div>
    <h3>Pagina não encontrada.</h3>
    <Link to='/app/'>Ir Para Pagina Inicial</Link>
    </div>
   )
}
function App() {
  return (
    <div className="App">
         <BrowserRouter>
            <Routes>
              <Route path='/app' element={<Home/>}/>
              <Route path='/app'>
                <Route path='login' element={<SignIn/>}/>
                <Route path='dashboard/*' element={<Dashboard/>} />
                <Route path='privacy' element={<PrivacyPolitical location={location} email={email} fbPermissions={fbPermissions} site={window.location.host}/>}/>
                <Route path='privacy/simple' element={<PrivacyPoliticalSimple location={location} fbPermissions={fbPermissions} email={email} site={window.location.host}/>}/>
                <Route path='cookie-policy' element={<CookiesPolicy site={window.location.host} location={location} email={email}/>} />
              </Route>
              <Route path='*' element={<Error404/>}/>
            </Routes>
         </BrowserRouter>
         <Snack/>
    </div>
  );
}

export default App;
