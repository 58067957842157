import { DataGrid } from "./datagrid";
import React from "react";
export var updatedServerList=null;
export class ServerList extends React.Component{
    constructor(props){
        super(props);
        this.state={isUpdating:true,list:[]};
    }
    componentDidMount(){
          this.getServerList();
          if(this.props.getUpdate)
            this.props.getUpdate(this.getServerList.bind(this));
          updatedServerList=this.getServerList.bind(this);
    }
    
    async getServerList(){
        this.startTime=new Date();
        var request = new XMLHttpRequest();
        request.open("POST", this.props.url);
        request.onreadystatechange=(e)=>{
            let r=e.currentTarget;
            if(r.readyState<3){
                this.setState({isUpdating:true,list:[]});
                return;
            }
            if(r.readyState===4){
               const restTime=750-((new Date())-this.startTime);
               if(restTime>0){
                  setTimeout(readyState4.bind(this,r),restTime);
               }else{
                  readyState4.bind(this)(r);
               }
               
            }    
        };
        request.send();
        function readyState4(r){
            let state={isUpdating:false,list:[]}; 
               if(r.status===200){
                  let data=JSON.parse(r.responseText);
                  data=this.props.processData?this.props.processData(data):data;
                  if(data.is_ok){
                     Object.assign(state,{list:data.data});
                  }
               }
               this.setState(state);
        }
    }

    render(){
        return (
            <React.Fragment>
              <DataGrid pushItems={this.props.addItems} orderFieldId={this.props.orderFieldId} header={this.props.header} filters={this.props.filters} items={this.state.list} isUpdating={this.state.isUpdating||this.props.isUpdating}/>
            </React.Fragment>
        )
    }
}