import React, { useEffect, useState } from "react"
import { Galery } from "../images/galery"
import { Typography } from "@mui/material"
import { sendServerRequest } from "../../utils/sendservr";
import { UniquePostExemple } from "./models";
import { getImageUrl } from "../../global/img";
import { getInstagramList } from "../../global/instagram";
import { BasicSelect } from "../inputs";
import { updateSnackStatus } from "../snack";
import { LoadingButton } from "@mui/lab";
export function PreparedPosts(){
    const cols=window.innerWidth>950?5:window.innerWidth>600?3:3;
    const [posts,setPosts]=useState([]);
    const [images,setImages]=useState([]);
    const [selectedPost,setSelectedPost]=useState(false);
    const [iAccounts,setIAccounts]=useState(false);
    useEffect(()=>{
         sendServerRequest('/server/posts/created').then((r)=>{
                const {posts}=r.data.data;
                setPosts(posts);
         }).catch(e=>{
            updateSnackStatus({
                open:true,
                severity:'error',
                message:'Erro ao carregar posts.'
            });
         });
         getInstagramList().then(r=>{
            let ret=[];
            for(let k of r){
                ret.push({
                    label:k.user_name,
                    value:k.id,
                });
            }
            setIAccounts(ret);
         }).catch(e=>{
            updateSnackStatus({
                open:true,
                severity:'error',
                message:'Erro ao carregar contas do instagram.'
            });
         })
    },[]);
    useEffect(()=>{
        let imgs=[];
        for(let post of posts){
            imgs.push({
                id:post.imgs_id_array,
            });
        };
        setImages(imgs);
    },[posts])
    return (<>
       <div style={{marginBottom:'30px'}}>
          <Typography variant="h5">Posts Prontos</Typography>
       </div>
       <Galery imageSelected={imageSelected} images={images} refs={posts} width={(100+10)*cols} cols={cols}/>
       {selectedPost?
        <div>
        <UniquePostExemple editable url={getImageUrl({id:selectedPost.imgs_id_array})} hashtags={selectedPost.hashtags_array} post={selectedPost} descripton={selectedPost.content} configs={[]}/>
        {iAccounts?
          <MakePost iAccounts={iAccounts} selectedPost={selectedPost}/>
        :null}
        </div>
       :null}
       
    </>);
    function MakePost({iAccounts,selectedPost}){
        const [iAccount,setIAccont]=useState(false);
        const [posted,setPosted]=useState(false);
        const [loading,setLoading]=useState(false);
        return(
        <div style={{margin:'auto',maxWidth:'500px',marginTop:'30px',marginBottom:'50px'}}> 
        <Typography variant="h6">Selecione a conta para Postar</Typography>
        <BasicSelect items={iAccounts} onItemChange={setIAccont} label={'Conta do instagram'} />
        <LoadingButton disabled={wasPosted()} onClick={postOnInstagram} loading={loading} fullWidth variant="contained">Postar</LoadingButton>
        </div>)
        async function postOnInstagram(){
            setLoading(true);
            try{
                await sendServerRequest(`/server/posts/post/${selectedPost.id}/${iAccount.value}`);
                setPosted({
                    insta:iAccount.value,
                    post:selectedPost.id
                });
                updateSnackStatus({
                    open:true,
                    severity:'success',
                    message:'Post realizado com sucesso.',
                });
            }catch(e){
                updateSnackStatus({
                    open:true,
                    severity:'error',
                    message:'Erro ao postar no instagram.',
                });
            }
            setLoading(false);
        }
        function wasPosted(){
            if(!iAccount)
               return true;
            return (posted&&posted.post===selectedPost.id&&posted.insta===iAccount.value);
        }
    }
    
    function imageSelected(img,post){
        setSelectedPost(post);
    }
}