import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { sendServerRequest } from "../utils/sendservr";
import { URLS } from "../env/urls";
import { redirectToLoginPage } from "../utils/redirects";
export function Logout(){
    useEffect(()=>{
        sendServerRequest(URLS.user.logout).then(()=>{
             redirectToLoginPage();
        }).catch(e=>{
             redirectToLoginPage();
        })
    })
    return (
        <div style={{textAlign:'center'}}>
            <p>Saindo</p>
            <CircularProgress/>
        </div>
    )
}