import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { getUserData } from '../global/user';
import {TextField} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {Grid} from '@mui/material';
import { loginWithPassword } from '../global/user';
import { redirectToDashboard } from '../utils/redirects';
import { PasswordTextField } from './inputs';
import { updateSnackStatus } from './snack';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://github.com/JimyRFP">
        Rafael Floriani Pinto
      </Link>{' '}
      2022
      {'.'}
    </Typography>
  );
}

function LoginBox(props){
   const {loading,onClickLogin}=props;
   return (
    <Box component="form" onSubmit={props.handleSubmit} noValidate sx={{ mt: 1 }}>
    <TextField
      margin="normal"
      required
      fullWidth
      id="email"
      label="Email"
      name="email"
      autoComplete="email"
      autoFocus
    />
    <PasswordTextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Senha"
      id="password"
      autoComplete="current-password"

    />
    <LoadingButton 
      fullWidth
      onClick={onClickLogin}
      variant="contained"
      loading={loading}
      sx={{ mt: 3, mb: 2 }}
    >
      Logar
    </LoadingButton >
  </Box>
   )
}

export default function SignIn() {
  const [mustLogin,setMustLogin]=React.useState(false);  
  const [loadingLogin,setLoadingLogin]=React.useState(false);
  useEffect(()=>{
    getUserData().then((user)=>{
        redirectToDashboard();
    }).catch(e=>{
        setMustLogin(true)
    });
  });
  async function loginWithPass(){
      const email=document.getElementById("email").value;
      const pass=document.getElementById("password").value;
      setLoadingLogin(true);
      loginWithPassword(email,pass).then((e)=>{
         window.location.reload();
      }).catch(e=>{
         setLoadingLogin(false);
         updateSnackStatus({
            open:true,
            message:'Login ou senha incorretos.',
            severity:'error',
         });
      })
      
  }
  return (

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor:'secodary.main'}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {!mustLogin?"Checando conexao":"Faça Login Para Continuar"}
          </Typography>
          {
            !mustLogin?<div style={{textAlign:"center"}}><CircularProgress/></div>:
            <div>
               <LoginBox onClickLogin={loginWithPass} loading={loadingLogin}/>
            </div>

          }
          
        </Box>
        <Typography variant="subtitle2" align="center"></Typography>
        <Copyright sx={{ mt: 8, mb: 4 }} />

      </Container>
  );

}