import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import React from "react";


export var updateSnackStatus=updateSnackStatus?updateSnackStatus:()=>null;
export class Snack extends React.Component{
    constructor(props){
        super(props);
        this.state={open:false,message:"",severity:"info"}
        updateSnackStatus=this.setState.bind(this);
    }
    snackClose(){
        this.setState({open:false});
    }

    render(){
        return (

            <Snackbar
                        open={this.state.open}
                        onClose={()=>{this.snackClose()}}               
                    >
                        <Alert onClose={()=>{this.snackClose()}} severity={this.state.severity} sx={{ width: '100%' }}>
                            {this.state.message}
                        </Alert>
            </Snackbar>
        )
    }
}