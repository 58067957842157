import { sendServerRequest } from "../utils/sendservr";
import { URLS } from "../env/urls";
export async function getUserData(){
   try{
      const sr=await sendServerRequest(URLS.user.getUserData,"","POST");
      return sr;
   }catch(e){
       throw e;
   }
}

export async function loginWithPassword(email,password){
    try{
       const sr=await sendServerRequest(URLS.user.login,JSON.stringify({email,password}),"POST");
       if(sr.data.hasError)
          throw sr;
       return sr;
    }catch(e){
        throw e;
    }
}