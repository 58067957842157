import { sendServerRequest } from "../utils/sendservr";
export async function getScheduleds(insta){
    try{
        if(!window.userScheduleds)
            window.userScheduleds={};
        if(!window.userScheduleds[insta]){
          const result=await sendServerRequest(`/server/posts/schedule/get/${insta}`);
          const {schedules}=result.data.data;
          let ret=[];
          for(let i=0;i<schedules.length;i++){
           schedules[i].date=new Date(schedules[i].date);
          }
          window.userScheduleds[insta]=schedules;
        }
        return window.userScheduleds[insta];

    }catch(e){
        throw e;
    }
}
export function restartScheduleds(){
    window.userScheduleds=null;

}