import Calendar from 'react-calendar';
import { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import './../../css/calendar.css';
import { BasicSelect, NumberField } from '../inputs';
import { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, TextField, Typography } from '@mui/material';
import { ModelsServerGalery, SelectWithTypeModels, UniquePostExemple } from './models';
import { getModelsImageConfigsByType } from '../../global/models';
import { updateSnackStatus } from '../snack';
import { ModelsGalery } from './models';
import { getImageUrl } from '../../global/img';
import TimePickerValue from '../hourinput';
import {formatDateString} from '../../utils/string';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { sendServerRequest } from '../../utils/sendservr';
import { LoadingDiv } from '../loading';
import { getScheduleds } from '../../global/schedules';
import { useRef } from 'react';
import { restartScheduleds } from '../../global/schedules';
import { getInstagramList } from '../../global/instagram';
export function PostShedule(){
    return (
        <div>
            <PostCalendar/>
        </div>
    ) 
}

function isSameDay(a,b){
   if(a.getDate()!==b.getDate())
     return false;
   if(a.getMonth()!==b.getMonth())  
      return false; 
   if(a.getFullYear()!==b.getFullYear())
     return false;  
   return true;  
}
function PostCalendar(){
    const now=new Date();
    const [startYear,setYear]=useState(now.getFullYear());
    const [minDate,setMinDate]=useState(getStartDate(startYear));
    const [dateOpen,setDateOpen]=useState(false);
    const [scheduleds,setScheduled]=useState(false);
    const [data,setSchedule]=useState(false);
    const [insta,setInsta]=useState(false);
    const [iAccounts,setIAccounts]=useState(false);
    useEffect(()=>{
       if(!iAccounts){
           getInstagramList().then(e=>{
                let ret=[];
                for(let k of e){
                    ret.push({
                        label:k.user_name,
                        value:k.id,
                    });
                }
                setIAccounts(ret);
           }).catch(e=>{
              updateSnackStatus({
                open:false,
                message:'Error ao carregar contas do instagram, atualize a página',
                severity:'error',
              })
           });
           return;
       }
    },[]);
    useEffect(()=>{
       setMinDate(getStartDate(startYear));
    },[startYear]);
    useEffect(()=>{
        if(insta)
          upsche();
     },[insta])
    if(!iAccounts){
       return <div style={{marginTop:'30px'}}><LoadingDiv>Carregando contas do Instagram..</LoadingDiv></div>
    }
    return (
       <div style={{marginTop:'30px'}}>
           <div className='insta_select'>
           <BasicSelect items={iAccounts} label='Conta Instagram' onItemChange={(e)=>{setInsta(e.value)}}/>
           </div>
           {scheduleds?
           <>
           <div style={{marginBottom:"30px",marginTop:'20px'}}>
               <NumberField label='Ano de inicio' onChange={setYear} defaultValue={now.getFullYear()} min={now.getFullYear()} max={now.getFullYear()+2}/>
           </div>
           <div>
              
              <div>
          
              </div>
           </div>
           <Dates scheduleds={scheduleds} onChange={onChangeDate} startDate={minDate}/>
           <CreatePostShedule insta={insta} upsche={upsche} data={data} onClickOut={()=>setDateOpen(false)} isOpen={Boolean(dateOpen)} date={dateOpen}/>
           </>:<>
           {insta?
           <LoadingDiv>Carregando agendamentos..</LoadingDiv>:null}
           </>} 
       </div>
    )
    function onChangeDate(date){
        for(let schedule of scheduleds){
            if(isSameDay(schedule.date,date)){
                setSchedule(schedule);
                setDateOpen(date);
                return;
            }
        }
        setDateOpen(date);
        setSchedule(false);

    }
    function upsche(){
        getScheduleds(insta).then((r)=>{
            setScheduled(r);
        }).catch(e=>{
            updateSnackStatus({
                open:true,
                message:'Erro ao carregar agendamentos, atualize a página.',
                severity:'error',
            });
        });
    }
    function getStartDate(sy){
        if(sy==now.getFullYear())
          return now;
        return new Date(sy,0,1,0,0,0,0);
    }
    function Dates({startDate,onChange,scheduleds}){
        let ret=[];
        let ref=useRef({lastIndex:0});
        ret.push(<CadMonth min={startDate}/>);
        let lastDate=startDate;  
        for(let i=0;i<11;i++){
            lastDate=new Date(lastDate);
            const lastMonth=lastDate.getMonth();
            const lastYear=lastDate.getFullYear();
            const month=lastMonth==11?0:lastMonth+1;
            const year=lastMonth==11?lastYear+1:lastYear;
            lastDate.setDate(1);
            lastDate.setMonth(month);
            lastDate.setFullYear(year);
            
            ret.push(<CadMonth min={lastDate} />)
        }
        return (
            <>
             {ret.map(e=><div style={{display:'inline-block'}}>{e}</div>)}
            </>
        )
        function CadMonth({min}){
            let max=new Date();
            const month=min.getMonth();
            const year=min.getFullYear();
            max.setFullYear(month==11?year+1:year,month==11?month==0:month+1,0);
            return <Calendar tileClassName={checkIsScheduled} onChange={onChange} minDate={min} maxDate={max} defaultValue={min} maxDetail='month'/>
        }
        function checkIsScheduled({ activeStartDate, date, view }){
            for(let i=ref.current.lastIndex;i<scheduleds.length;i++){
                let she=scheduleds[i];
                const sDate=she.date;
                if((sDate.getTime()-86400000)>date.getTime())
                   return null;
                if(!isSameDay(sDate,date))
                   continue;
                ref.current.lastIndex=i;   
                return 'has_schedule';
            }
            return null;
        } 
    }
}


function CreatePostShedule({isOpen,date,data,onClickOut,upsche,insta}){
    const [theme,setTheme]=useState(data?data.theme:null);
    const [opened,setOpended]=useState(false);
    const [autoPost,setAutoPost]=useState(false);
    const [selectedModel,setSelectedModel]=useState(false);
    const [titleText,setTitleText]=useState('');
    const [improveText,setImproveText]=useState(false);
    const [hour,setHour]=useState(false);
    const [updating,setUpdating]=useState(false);
    const [scheduleId,setScheduleId]=useState(false);
    useEffect(()=>{ 
       if(isOpen&&!opened){
          setTheme(data?data.theme:null);
          setOpended(true);
          setAutoPost(data?data.autoPost:false);
          setTitleText(data?data.titleText:null);
          setImproveText(data?data.improveText:true);
          setSelectedModel(data?{id:data.modelId}:null);

          setHour(dayjs(!data?'2022-04-17T18:30':`2022-04-17T${fZero(data.date.getHours())}:${fZero(data.date.getMinutes())}`))
          setScheduleId(data?.scheduleId);
          function fZero(n){
            if(n>9)
              return n;
            return `0${n}`  
          }
       }
       if(!isOpen&&opened){
          setOpended(false);
       }
    });
    return (
        <Dialog open={isOpen&&opened}>
              <DialogTitle>Agendamento de postagem.</DialogTitle>
              <DialogContent >
                  <div>
                    <TextField disabled={updating} onChange={(i)=>setTheme(i.target.value)} defaultValue={theme} sx={{marginTop:'8px'}} fullWidth label={'Tema a se falar.'}/>
                    <TextField disabled={updating} onChange={(i)=>setTitleText(i.target.value)} sx={{marginTop:'8px'}} fullWidth label={'Texto títilo da imagem'} defaultValue={titleText}/>
                    {titleText?<div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <Typography variant='subtitle1'>Corrigir ou Melhorar Texto:</Typography>
                        <Switch disabled={updating} checked={improveText} onChange={(e)=>{setImproveText(e.target.checked)}}/>
                    </div>:null}
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'15px'}}>
                        <Typography variant='subtitle1'>Postar de forma automática:</Typography>
                        <Switch disabled={updating} checked={autoPost} onChange={(e)=>{setAutoPost(e.target.checked)}}/>
                    </div>
                    <div style={{textAlign:'center'}}>
                        <Typography variant='subtitle1'>{`Data: ${date&&formatDateString(date,false)}`}</Typography>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',marginTop:'15px',alignItems:'center'}}>
                         <TimePickerValue disabled={updating} defaultValue={hour} onChange={setHour} label={'Horário de postagem'}/>
                    </div>
                    <ModelsServerGalery defaultModel={selectedModel} onSetModel={setSelectedModel}/>

                  </div>
              </DialogContent>
              <DialogActions>
                 <Button variant='contained' disabled={updating} onClick={onClickOut}>Sair</Button>
                 {scheduleId?<Button sx={{color:'red'}} onClick={deleteSchedule} disabled={updating}>Deletar</Button>:null}
                 <LoadingButton loading={updating} variant='contained' onClick={registerOrEdit} disabled={!theme||!selectedModel}>{scheduleId?'Editar':'Salvar'}</LoadingButton>
              </DialogActions>
        </Dialog>
    )
    async function deleteSchedule(){
        try{
           setUpdating(true); 
           await sendServerRequest(`/server/posts/schedule/delete/${data.scheduleId}`);
           restartScheduleds();
           onClickOut();
           upsche();
        }catch(e){
            updateSnackStatus({
                open:true,
                message:'Erro ao deletar agendamento.',
                severity:'error',
            })
        }
        setUpdating(false); 
    }
    async function registerOrEdit(){
        setUpdating(true);
        let useDate=new Date(date);
        useDate.setHours(hour.$H,hour.$m);
        const data={
            theme,

            titleText,
            modelId:selectedModel.id,
            improveText:improveText?1:0,
            autoPost:autoPost?1:0,
            date:useDate.getTime(),

        };
        let url=`/server/posts/schedule/create/${insta}`;
        if(scheduleId){
            url=`/server/posts/schedule/edit/${scheduleId}`;
        }
        try{
            const result=await sendServerRequest(url,JSON.stringify(data));
            updateSnackStatus({
                open:true,
                message:'Atualizado com sucesso.!',
                severity:'success',
            });
            if(!scheduleId)
            setScheduleId(result.data.data.id);
        }catch(e){
            updateSnackStatus({
                open:true,
                message:'Erro ao salvar/editar, por favor, autalize a página.',
                severity:'error',
            });
        }
        restartScheduleds();
        upsche();
        setUpdating(false);
    }
}