
export function sendServerRequest(url,data,type='POST'){
    const request = new XMLHttpRequest();
    request.open(type, url);    
    if(!(data instanceof FormData))
      request.setRequestHeader("Content-type",'application/json');
    request.send(data);
    return new Promise((resolve,reject)=>{
        request.onreadystatechange=orsc;
        function orsc(r){
           const target=r.currentTarget;
           if(target.readyState==4){
               if(target.status>=200 && target.status<300){
                   const data=JSON.parse(target.responseText);
                   resolve({status:target.status,data:data,target:target});
               }
               reject({target:target});
           }                
        }
    });
}

export function sendServerRequestWitoutJSONParse(url,data,type='POST'){
    const request = new XMLHttpRequest();
    request.open(type, url);    
    if(!(data instanceof FormData))
      request.setRequestHeader("Content-type",'application/json');
    request.send(data);
    return new Promise((resolve,reject)=>{
        request.onreadystatechange=orsc;
        function orsc(r){
           const target=r.currentTarget;
           if(target.readyState==4){
               if(target.status>=200 && target.status<300){
                   const data=target.responseText;
                   resolve({status:target.status,data:data,target:target});
               }
               reject({target:target});
           }                
        }
    });
}