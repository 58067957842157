import React from "react";
export const FONTS=[
    {family:'Arial',isDefault:true},
    {family:'Verdana',isDefault:true},
    {family:'Helvetica',isDefault:true},
    {family:'Trebuchet MS',isDefault:true},
    {family:'Courier New',isDefault:true},
    {family:'Comic Sans MS',isDefault:true},
    {family:'Brush Script MT',isDefault:true},
    {family:'Impact',isDefault:true},
    {family:'Lucida Sans',isDefault:true},
    {family:'Constantia',isDefault:true},
];

export function getFontSelectItems(){
    let ret=[];
    for(let font of FONTS){
      ret.push({
        label:font.family,
        value:font.family,
        fontFamily:font.family,
      });
    } 
    return ret;
}










